import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { ADD_KEYWORD, DELETE_KEYWORD } from '../Constants';

const arePropsEqual = (prev, next) =>
  prev.data.keywords === next.data.keywords;

const Keywords = React.memo(({ data, dispatch }) => {
  const [ value, setValue ] = useState('');

  const handleChange = (e) =>
    setValue(e.target.value);

  const handleDelete = (index) =>
    dispatch({
      type: DELETE_KEYWORD,
      value: index,
    });

  const handleSubmit = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();

      dispatch({
        type: ADD_KEYWORD,
        value: value,
      });

      setValue('');
    }
  };

  return (
    <TextField
      name="keywords"
      label="Keywords"
      variant="standard"
      value={value}
      onChange={handleChange}
      onKeyDown={handleSubmit}
      InputProps={{
        startAdornment: (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            maxHeight: '100px', // Adjust the max height as needed
            overflow: 'auto',
            gap: (theme) => theme.spacing(1),
          }}
        >
          {data.keywords && data.keywords.map((keyword, index) => (
            <Chip
              key={keyword}
              label={keyword}
              onDelete={() => handleDelete(index)}
              size="small"
            />
          ))}
        </Box>
      )
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    />
  );
}, arePropsEqual);

export default Keywords;
