import React, { useState } from 'react';
import { doc, getDoc } from 'firebase/firestore'; 
import { firestore } from 'utilities/firebase';

// Components
import ContentForm from 'forms/Content';

// UI
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Actions = React.memo(({ section, subsection }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [ formOpen, setFormOpen ] = useState(false);
  const [ formData, setFormData ] = useState();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleEditIndex = async () => {
    const indexRef = doc(firestore, section, subsection);
    const index = await getDoc(indexRef);

    setFormData(index);
    setFormOpen(true);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="actions"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={handleEditIndex}>
          Edit page
        </MenuItem>  
      </Menu>

      <ContentForm
        open={formOpen}
        close={() => setFormOpen(false)}
        section="section"
        subsection="index"
        data={formData}
        save={() => setFormOpen(false)}
      />
    </React.Fragment>
  );
});

export default Actions;
