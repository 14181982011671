import React from 'react';
import { firestore } from 'utilities/firebase';
import { collection, doc } from "firebase/firestore";
import { useFirestoreDocumentDeletion } from "@react-query-firebase/firestore";

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const Delete = ({ id }) => {
  const col = collection(firestore, "news", "articles", "content");
  const ref = doc(col, id);
  const mutation = useFirestoreDocumentDeletion(ref);

  return (
    <IconButton onClick={() => mutation.mutate()}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export default Delete;
