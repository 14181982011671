import React, { useState, useRef, useEffect } from 'react';
import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";
import memoizeOne from 'memoize-one';
import loadjs from 'loadjs';

const getConfig = async () =>
  httpsCallable(functions, 'getCloudinaryConfig')()

const getScript = async () =>
  loadjs(
    ["https://media-library.cloudinary.com/global/all.js"],
    'cloudinaryMediaLibraryWidget',
    { returnPromise: true });

const createCloudinaryMediaLibrary = memoizeOne(async () => {
  const config = await getConfig();

  if (!loadjs.isDefined('cloudinaryMediaLibraryWidget'))
    await getScript();

  return cloudinary.createMediaLibrary({ 
    cloud_name: "dev-and-gear",
    api_key: config.data.api_key,
    username: config.data.username,
  });
});

const CloudinaryLibrary = ({ children, onInsert }) => {
  const [ mounted, setMounted ] = useState(false);
  const [ data, setData ] = useState();
  const clientRef = useRef();

  useEffect(() => {
    if (mounted)
      setData(null);

    if (!mounted && data) {
      const images = data.assets.reduce((acc, image) => {
        acc.push({
          url: `/v${image.version}/${image.public_id}`,
          width: image.width,
          height: image.height,
        });

        return acc;
      }, []);

      onInsert(images);
    };
  }, [mounted]);

  const handleClick = async () => {
    if (!clientRef.current)
      clientRef.current = await createCloudinaryMediaLibrary();

    clientRef.current.once('show', handleShow);
    clientRef.current.once('hide', handleHide);
    clientRef.current.once('insert', handleInsert);

    clientRef.current.show();
  };

  const handleShow = () => setMounted(true);
  const handleHide = () => setMounted(false);
  const handleInsert = (data) => setData(data);

  return (
    <div onClick={handleClick}>
      {children}
    </div>
  );
};

export default CloudinaryLibrary;
