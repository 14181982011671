import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from '@mui/lab/DatePicker';

import {
  ADD_LINK_FIELD,
  DELETE_LINK_FIELD,
  EDIT_LINK_FIELD,
} from '../../Constants';

const fieldsList = [
  { label: 'Date', value: 'date' },
  { label: 'Source', value: 'source' },
  { label: 'Title', value: 'title' },
  { label: 'URL', value: 'url' },
];

const Link = ({ data, dispatch, index }) => {
  const [ editing, setEditing ] = useState(false);

  const handleAddField = (field, index) =>
    dispatch({
      type: ADD_LINK_FIELD,
      value: {
        field: field.target.value,
        value: '',
        index,
      },
    });

  const handleEditField = (e, index) => {
    const { name, value } = e.target;

    dispatch({
      type: EDIT_LINK_FIELD,
      value: {
        field: name,
        value,
        index,
      },
    });
  };

  const handleDateEditField = (value, index) => {
    dispatch({
      type: EDIT_LINK_FIELD,
      value: {
        field: 'date',
        value,
        index,
      },
    });
  };

  const handleDeleteField = (name) =>
    dispatch({
      type: DELETE_LINK_FIELD,
      value: {
        field: name,
        index,
      },
    });

  return (
    <Stack
      component={Paper}
      spacing={1}
      sx={{ flex: 1, padding: (theme) => theme.spacing(2) }}
    >
      {Object.keys(data).map((field) =>
        <Stack
          key={field}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          {field === 'date'
            ? <DatePicker
                renderInput={(props) =>
                  <TextField variant="standard" {...props} sx={{ flex: 1 }} />
                }
                label={field}
                ampm={false}
                value={data[field].seconds ? new Date(data[field].seconds * 1000) : data[field]}
                onChange={(e) => handleDateEditField(e, index)}
              />
            : <TextField
                name={field}
                label={field}
                variant="standard"
                value={data[field]}
                onChange={(e) => handleEditField(e, index)}
                required
                fullWidth
              />
          }

          {editing &&
            <IconButton onClick={() => handleDeleteField(field)} size="small">
              <DeleteIcon />
            </IconButton>
          }
        </Stack>
      )}

      <Stack direction="row" alignItems="center" spacing={2}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id={`field-select-label-${index}`}>
            Add field
          </InputLabel>

          <Select
            labelId={`field-select-label-${index}`}
            id={`field-select-${index}`}
            value=""
            label="Add field"
            onChange={(e) => handleAddField(e, index)}
          >
            {fieldsList
                .filter((field) => !Object.keys(data).find((key) => key === field.value))
                .map((field) =>
              <MenuItem key={field.value} value={field.value}>
                {field.label}
              </MenuItem>
            )}
          </Select>
        </FormControl> 

        <Button variant="outlined" onClick={() => setEditing(!editing)}>
          {editing ? 'Done' : 'Edit'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default Link;
