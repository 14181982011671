import React, { useEffect, useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { SET_DATA } from '../Constants';

// const arePropsEqual = (prev, next) => prev.data.title === next.data.title;

const ActivePeriod = ({ data, dispatch }) => {
  const [ from, setFrom ] = useState();
  const [ to, setTo ] = useState();

  useEffect(() => {
    data.activePeriod.from.seconds 
      ? setFrom(new Date(data.activePeriod.from.seconds * 1000))
      : setFrom(data.activePeriod.from);

    data.activePeriod.to.seconds 
      ? setTo(new Date(data.activePeriod.to.seconds * 1000))
      : setTo(data.activePeriod.to);
  }, [data.activePeriod.from, data.activePeriod.to]);

  const handleChange = (period, value) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: 'activePeriod',
        value: {
          ...data.activePeriod,
          [period]: value,
        },
      },
    });

  return (
    <Stack direction="row" spacing={2}>
      <DatePicker
        renderInput={(props) =>
          <TextField variant="standard" {...props} sx={{ flex: 1 }} />
        }
        label="From"
        ampm={false}
        value={from}
        onChange={(val) => handleChange('from', val)}
      />

      <DatePicker
        renderInput={(props) =>
          <TextField variant="standard" {...props} sx={{ flex: 1 }} />
        }
        label="To"
        ampm={false}
        value={to}
        onChange={(val) => handleChange('to', val)}
      />
    </Stack>
  );
}; //, arePropsEqual);

export default ActivePeriod;
