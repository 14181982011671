import { arrayMove } from "@dnd-kit/sortable";
import { seller } from './Model';

import {
  SET_STATE,

  SET_DATA,
  DELETE_DATA,

  ADD_TAG,
  DELETE_TAG,

  ADD_KEYWORD,
  DELETE_KEYWORD,

  ADD_LINK,
  ADD_LINK_FIELD,
  DELETE_LINK_FIELD,
  EDIT_LINK_FIELD,
  DELETE_LINK,

  ADD_IMAGE,
  EDIT_IMAGE,
  DELETE_IMAGE,

  SET_COVER,

  SET_GALLERY,
  ADD_GALLERYIMAGE,
  EDIT_GALLERYIMAGE,
  DELETE_GALLERYIMAGE,
  MOVE_GALLERYIMAGE,

  ADD_TAXONOMY,
  DELETE_TAXONOMY,
  ADD_TAXONOMY_ITEM,
  MOVE_TAXONOMY_ITEM,
  DELETE_TAXONOMY_ITEM,

  ADD_SELLER,
  REMOVE_SELLER,
  EDIT_SELLER,
  SET_ACTIVE,
  SET_SELLER_PRICE,
  SET_PRIMARY_SELLER,
  MOVE_SELLER,
} from '../Constants';

const Reducer = (state, action) => {
  switch (action.type) {
    case SET_STATE: {
      const { value } = action;

      return {
        ...value,
      };
    }

    case SET_DATA: {
      const { field, value } = action.value;
      
      return {
        ...state,
        [field]: value,
      };
    }

    case DELETE_DATA: {
      const { value } = action;
      const stateCopy = {...state};

      delete stateCopy[value];
      
      return {
        ...stateCopy,
      };
    }

    case ADD_TAG: {
      const { value } = action;
      const tags = state.tags ? [...state.tags] : [];

      tags.push(value);
      
      return {
        ...state,
        tags: [
          ...tags,
        ]
      };
    }

    case DELETE_TAG: {
      const { value } = action;
      
      return {
        ...state,
        tags: [
          ...state.tags.filter((tag, index) => index !== value)
        ]
      };
    }

    // Keywords
    case ADD_KEYWORD: {
      const { value } = action;
      const keywords = state.keywords ? [...state.keywords] : [];

      keywords.push(value);
      
      return {
        ...state,
        keywords: [
          ...keywords,
        ]
      };
    }

    case DELETE_KEYWORD: {
      const { value } = action;
      
      return {
        ...state,
        keywords: [
          ...state.keywords.filter((keyword, index) => index !== value)
        ]
      };
    }

    // Links
    case ADD_LINK: {
      const { value } = action;
      
      return {
        ...state,
        links: [
          ...state.links,
          {
            ...value,
          },
        ]
      };
    }

    case ADD_LINK_FIELD: {
      const { field, value, index } = action.value;
      const stateCopy = {...state};

      stateCopy.links[index][field] = value;

      return {
        ...stateCopy
      }
    }

    case EDIT_LINK_FIELD: {
      const { field, value, index } = action.value;
      const stateCopy = {...state};

      stateCopy.links[index][field] = value;

      return {
        ...stateCopy
      }
    }

    case DELETE_LINK_FIELD: {
      const { field, index } = action.value;
      const stateCopy = {...state};

      delete stateCopy.links[index][field];

      return {
        ...stateCopy
      }
    }

    case DELETE_LINK: {
      const { value } = action;
      
      return {
        ...state,
        links: [
          ...state.links.filter((link, index) => index !== value)
        ]
      };
    }

    // Image object
    case ADD_IMAGE: {
      const { type, value } = action.value;
      
      return {
        ...state,
        image: {
          ...state.image,
          [type]: {
            ...value,
          },
        },
      };
    }

    case EDIT_IMAGE: {
      const { field, type, value } = action.value;
      
      return {
        ...state,
        image: {
          ...state.image,
          [type]: {
            ...state.image[type],
            [field]: value,
          },
        },
      };
    }

    case DELETE_IMAGE: {
      const { type } = action.value;

      const stateCopy = {...state};

      delete stateCopy['image'][type];
      
      return {
        ...stateCopy,
      };
    }

    // Cover image
    case SET_COVER: {
      const { field, value } = action.value;
      
      return {
        ...state,
        cover: {
          ...state.cover,
          [field]: value,
        },
      };
    }

    // Gallery images
    case SET_GALLERY: {
      const { field, value } = action.value;
      
      return {
        ...state,
        gallery: {
          ...state.gallery,
          [field]: value,
        },
      };
    }

    case ADD_GALLERYIMAGE: {
      const { value } = action;
      
      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: [
            ...state.gallery.items,
            value,
          ]
        }
      };
    }

    case EDIT_GALLERYIMAGE: {
      const { index, value } = action.value;
      const galleryCopy = { ...state.gallery };

      galleryCopy.items[index] = value;
      
      return {
        ...state,
        gallery: {
          ...galleryCopy,
        }
      };
    }

    case DELETE_GALLERYIMAGE: {
      const { value } = action;
      
      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: [
            ...state.gallery.items.filter((image, index) => index !== value)
          ]
        }
      };
    }

   	case MOVE_GALLERYIMAGE: {
      const { active, over } = action.value;

      if (active.id !== over.id) {
        const oldIndex = state.gallery.items.findIndex(item => item.url === active.id);
        const newIndex = state.gallery.items.findIndex(item => item.url === over.id);
        const newOrder = arrayMove(state.gallery.items, oldIndex, newIndex);
        return {
          ...state,
          gallery: {
            ...state.gallery,
            items: [
              ...newOrder
            ]
          },
        };
      } else {
        return {
          ...state,
        };
      }; 
    }

    case ADD_TAXONOMY: {
      const { section, subsection, value } = action.value;

      return {
        ...state,
        partOf: {
          ...state.partOf,
          [section]: {
            ...state.partOf[section],
            [subsection]: [],
          }
        },
      };
    }

    case DELETE_TAXONOMY: {
      const { section, subsection } = action.value;

      const stateCopy = { ...state };

      delete stateCopy.partOf[section][subsection];
      
      return {
        ...stateCopy,
      };
    }

    case ADD_TAXONOMY_ITEM: {
      const { section, subsection, value } = action.value;

      return {
        ...state,
        partOf: {
          ...state.partOf,
          [section]: {
            ...state.partOf[section],
            [subsection]: [
              ...state.partOf[section][subsection],
              value,
            ],
          }
        },
      };
    }

   	case MOVE_TAXONOMY_ITEM: {
      const { section, subsection, value } = action.value;
      const { active, over } = value;

      if (active.id !== over.id) {
        const oldIndex = state.partOf[section][subsection].findIndex(item => item === active.id);
        const newIndex = state.partOf[section][subsection].findIndex(item => item === over.id);
        const newOrder = arrayMove(state.partOf[section][subsection], oldIndex, newIndex);

        return {
          ...state,
          partOf: {
            ...state.partOf,
            [section]: {
              ...state.partOf[section],
              [subsection]: [
                ...newOrder,
              ],
            }
          },
        };
      }; 
    }

    case DELETE_TAXONOMY_ITEM: {
      const { section, subsection, value } = action.value;

      return {
        ...state,
        partOf: {
          ...state.partOf,
          [section]: {
            ...state.partOf[section],
            [subsection]: [
              ...state.partOf[section][subsection].filter((image, index) => index !== value),
            ],
          }
        },
      };
    }

    case ADD_SELLER: {
      const { id, name, weight } = action.value;

      return {
        ...state,
        sellers: {
          ...state.sellers,
          [id]: {
            ...seller(id, name, weight),
          },
        },
      };
    }

    case REMOVE_SELLER: {
      const id = action.value;

      delete state.sellers[id];

      Object.keys(state.sellers)
        .sort((a, b) => state.sellers[a].weight > state.sellers[b].weight ? 1 : -1)
        .forEach((item, index) => state.sellers[item].weight = index + 1);

      return {
        ...state,
      };
    }

    case EDIT_SELLER: {
      const { id, field, value } = action.value;
  
      return {
        ...state,
        sellers: {
          ...state.sellers,
          [id]: {
            ...state.sellers[id],
            [field]: value,
          },
        },
      };
    }

    case SET_ACTIVE: {
      const { id, field, value } = action.value;

      const newState = {
        ...state,
        sellers: {
          ...state.sellers,
          [id]: {
            ...state.sellers[id],
            [field]: value,
          },
        },
      };

      // Is there any active store
      const isProductActive = Object.keys(newState.sellers)
        .some((seller) => newState.sellers[seller].active === true);

      // Check if primary is active
      const isPrimaryActive = newState.sellers[newState.seller.store.id].active;

      // If yes and length yes set new primary
      if (!isPrimaryActive && isProductActive && Object.keys(newState.sellers).length > 1) {
        const storeId = Object.keys(newState.sellers)
          .sort((a, b) => newState.sellers[a].weight > newState.sellers[b].weight ? 1 : -1)
          .find((seller) => newState.sellers[seller].active === true);

        newState.seller = newState.sellers[storeId];
      };

      return {
        ...newState,
        active: isProductActive,
      };
    }

    case SET_SELLER_PRICE: {
      const { id, field, value } = action.value;

      const price = {
        ...state.sellers[id].price,
        [field]: value,
      };

      const primary = price.primary
        ? parseFloat(price.primary)
        : 0;

      const original = price.original
        ? parseFloat(price.original)
        : 0;

      const savingAmount = primary && original
        ? Number((original - primary).toFixed(2))
        : 0;

      const savingPercentage = primary && original
        ? Math.round(100 - (primary / original) * 100)
        : 0;
      
      return {
        ...state,
        sellers: {
          ...state.sellers,
          [id]: {
            ...state.sellers[id],
            price: {
              ...price,
              primary: primary,
              original: original,
              savings: {
                amount: savingAmount,
                percentage: savingPercentage,
              }
            },
          },
        },
      };
    }

    case SET_PRIMARY_SELLER: {
      const id = action.value;

      const primary = state.sellers[id];

      return {
        ...state,
        seller: {
          ...primary,
        },
      };
    }

		case MOVE_SELLER: {
      const { active, over } = action.value;

      return {
        ...state,
        sellers: {
          ...state.sellers,
          [active.id]: {
            ...state.sellers[active.id],
            weight: state.sellers[over.id].weight,
          },
          [over.id]: {
            ...state.sellers[over.id],
            weight: state.sellers[active.id].weight,
          },
        },
      };
    }

    default:
      return null;
  }
};

export default Reducer;
