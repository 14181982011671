import React, { useState } from 'react';

// Actions
import GenerateRss from '../Actions/GenerateRss';
import UpdatePrices from '../Actions/UpdatePrices';
import PickBest from '../Actions/PickBest';
import EditIndexPage from '../Actions/editIndexPage';

// UI
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Actions = React.memo(() => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="deals-actions"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem>
          <UpdatePrices />
        </MenuItem>  

        <MenuItem>
          <GenerateRss />
        </MenuItem>  

        <MenuItem>
          <PickBest />
        </MenuItem>  

        <MenuItem>
          <EditIndexPage />
        </MenuItem>  
      </Menu>
    </React.Fragment>
  );
});

export default Actions;
