import React, { useState, useEffect } from 'react';
import { firestore } from 'utilities/firebase';
import { query, collection, where, doc, setDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useFirestoreQuery } from "@react-query-firebase/firestore";

import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import CircularProgress from '@mui/material/CircularProgress';
import { red, grey, orange } from '@mui/material/colors';

const IconsMap = {
  featured: LocalFireDepartmentIcon,
  best: StarIcon,
  top: LocalFireDepartmentIcon,
};

const Collection = ({ section, subsection, name, data }) => {
  const [ collectionId, setCollectionId ] = useState('');
  const ActiveIcon = IconsMap[name]
  const ActiveColor = name === 'top' ? orange : red;

  // Get collection ID
  const refQuery = query(
    collection(firestore, section, subsection, "content"),
    where('slug', '==', name)
  );

  const collectionQuery = useFirestoreQuery(
    [section, subsection, name],
    refQuery
  );

  useEffect(() => {
    if (!collectionQuery.isLoading)
      setCollectionId(collectionQuery.data.docs[0].id);
  }, [collectionQuery.isLoading]);

  // Add/Remove
  const docRef = doc(firestore, data.ref.path);

  const add = () =>
    setDoc(docRef, {
      partOf: {
        [section]: {
          [subsection]: arrayUnion(collectionId)
        }
      }
    }, { merge: true });

  const remove = () =>
    setDoc(docRef, {
      partOf: {
        [section]: {
          [subsection]: arrayRemove(collectionId)
        }
      }
    }, { merge: true });

  if (collectionQuery.isLoading)
    return <CircularProgress size={12} />

  return ( 
    <IconButton
      onClick={() => data.data().partOf[section] && data.data().partOf[section][subsection].includes(collectionId) ? remove() : add()}
    >
      <ActiveIcon
        fontSize="small"
        sx={{
          color: data.data().partOf[section] && data.data().partOf[section][subsection].includes(collectionId) ? ActiveColor[500] : grey[500]
        }}
      />
    </IconButton>
  );
};

export default Collection;
