import React from 'react';

import CloudinaryLibrary from 'components/CloudinaryLibrary';
import ImageKitLibrary from 'components/ImageKitLibrary';
import ImagePreview from 'components/ImagePreview';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CollectionsIcon from '@mui/icons-material/Collections';

import { ADD_IMAGE, EDIT_IMAGE, DELETE_IMAGE } from '../Constants';

// const arePropsEqual = (prev, next) => JSON.stringify(prev.data.image.static) === JSON.stringify(next.data.image.static);

const ImageStatic = ({ data, dispatch }) => {
  const imageType = 'static';

  const handleAdd = () => 
    dispatch({
      type: ADD_IMAGE,
      value: {
        field: 'image',
        type: imageType,
        value: {
          source: 'cloudinary',
          url: '',
          width: '',
          height: '',
          alt: '',
          author: '',
          author_url: '',
        },
      }
    });

  const dispatchChanges = (field, value) => 
    dispatch({
      type: EDIT_IMAGE,
      value: {
        field: field,
        type: imageType,
        value: value,
      }
    });

  const handleRemove = () =>
    dispatch({
      type: DELETE_IMAGE,
      value: {
        type: imageType,
      },
    });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'source') {
      dispatchChanges('url', '');
      dispatchChanges('width', '');
      dispatchChanges('height', '');
    };

    dispatchChanges(name, value);
  };
  
  const handleInsert = (image) => {
    dispatchChanges('source', data.image.static.source);
    dispatchChanges('url', image[0].url);
    dispatchChanges('width', image[0].width);
    dispatchChanges('height', image[0].height);
  }

  return (
    <Stack component={Paper} spacing={2} sx={{ padding: (t) => t.spacing(2) }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>
          Static image
        </Typography>

        <IconButton
          onClick={data.image && data.image.static ? handleRemove : handleAdd}
          size="small"
        >
          {data.image && data.image.static ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Stack>

      {data.image && data.image.static &&
        <React.Fragment>
          <Divider />

          <Stack
            component={Paper}
            direction={{
              xs: "column",
              sm: "row",
            }}
            spacing={2}
          >
            <Box sx={{ position: 'relative', flex: 1 }}>
              <ImagePreview source={data.image.static.source} url={data.image.static.url} />

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  position: 'absolute',
                  right: (t) => t.spacing(2),
                  bottom: (t) => t.spacing(2),
                }}
              >
                {data.image.static.source === 'cloudinary' &&
                  <CloudinaryLibrary onInsert={handleInsert}>
                    <IconButton
                      sx={{
                        backgroundColor: '#000',
                        color: '#fff',
                      }}
                      size="small"
                    >
                      <CollectionsIcon fontSize="small" />
                    </IconButton>
                  </CloudinaryLibrary>
                }

                {data.image.static.source === 'imagekit' &&
                  <ImageKitLibrary ke="static-image" onInsert={handleInsert}>
                      <IconButton
                        sx={{
                          backgroundColor: '#000',
                          color: '#fff',
                        }}
                        size="small"
                      >
                        <CollectionsIcon fontSize="small" />
                      </IconButton>
                    </ImageKitLibrary>
                }
              </Stack>
            </Box>	

            <Stack spacing={2} sx={{ flex: 1 }}>
              <Select
                name="source"
                value={data.image.static.source || 'cloudinary'}
                label="Source"
                variant="standard"
                onChange={handleChange}
              >
                <MenuItem value="external">External</MenuItem>
                <MenuItem value="cloudinary">Cloudinary</MenuItem>
                <MenuItem value="imagekit">ImageKit</MenuItem>
                <MenuItem value="unsplash">Unsplash</MenuItem>
                <MenuItem value="amazon">Amazon</MenuItem>
                <MenuItem value="twitter">Twitter</MenuItem>
              </Select>

              <TextField
                name="url"
                label="URL"
                variant="standard"
                value={data.image.static.url}
                onChange={handleChange}
                disabled={data.image.static.source === 'cloudinary'}
                required
              />

              <Stack direction="row" spacing={2}>
                <TextField
                  name="width"
                  label="Width"
                  variant="standard"
                  value={data.image.static.width}
                  onChange={handleChange}
                  disabled={data.image.static.source === 'cloudinary'}
                  required
                />

                <TextField
                  name="height"
                  label="Height"
                  variant="standard"
                  value={data.image.static.height}
                  onChange={handleChange}
                  disabled={data.image.static.source === 'cloudinary'}
                  required
                />
              </Stack>

              <TextField
                name="alt"
                label="Alt description"
                variant="standard"
                value={data.image.static.alt}
                onChange={handleChange}
                required
              />
            </Stack>
          </Stack>

          <TextField
            name="author"
            label="Author"
            variant="standard"
            value={data.image.static.author ? data.image.static.author : ''}
            onChange={handleChange}
          />

          <TextField
            name="author_url"
            label="Author URL"
            variant="standard"
            value={data.image.static.author_url ? data.image.static.author_url : ''}
            onChange={handleChange}
          />
        </React.Fragment>
      }
    </Stack>
  );
};

export default ImageStatic;
