import React from 'react';

import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";

const GenerateRss = () => {

  const handleClick = () =>
    Promise.all([
      httpsCallable(functions, 'manualRSSDealsBestGenerator')(),
      httpsCallable(functions, 'manualRSSDealsHotGenerator')(),
    ])

  return (
    <div onClick={handleClick}>
      Update RSS
    </div>
  );
};

export default GenerateRss;
