import React from 'react';
// import Firebase from 'firebase';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

Firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

const useStyles = styled((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const uiConfig = {
  signInFlow: 'redirect',
  signInSuccessUrl: '/',
  signInOptions: [
    {
      provider: Firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: Firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      disableSignUp: {
        'status': true,
        'adminEmail': 'bojan@devandgear.com',
      }
    }
  ],
};

const SignIn = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">

      <Paper
        elevation={3}
        className={classes.paper}
      >
        <Grid
          container
          direction="column"
        >
          <Grid item xs={12}>
            <Box mt={3} mb={3}>
              <Typography
                variant="h4"
                component="h1"
                align="center">
                Sign in
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={Firebase.auth()}
            />
          </Grid>
          
        </Grid>
      </Paper>
    </Container>
  )
};

export default SignIn
