import React, { useState, useEffect } from 'react';
import { firestore } from 'utilities/firebase';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  onSnapshot,
  limit,
  orderBy,
  // startAfter,
  deleteDoc,
} from 'firebase/firestore'; 

/*
import {
  useFirestoreQuery,
  // useFirestoreInfiniteQuery
} from '@react-query-firebase/firestore';
*/

import Card from './Components/Card';
import Actions from './Components/Actions';
import ContentForm from 'forms/Content';
import GenerateForm from 'forms/Generate';
import ConfirmDialog from 'components/ConfirmDialog';
import Search from 'components/Search';

import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const FileExplorer = ({ search, section, subsection, taxonomySection, taxonomySubsection, taxonomyName, order, published, hits, canGenerate }) => {
  const [ data, setData ] = useState([]);

  const [ formOpen, setFormOpen ] = useState(false);
  const [ formData, setFormData ] = useState();

  const [ generateFormOpen, setGenerateFormOpen ] = useState(false);
  const [ generateFormData, setGenerateFormData ] = useState();

  const [ deleteDocRef, setDeleteDocRef ] = useState();
  const [ deleteDialogOpen, setDeleteDialogOpen ] = useState(false);

  useEffect(() => {
    const conditions = [];

    if (taxonomySection) {
      conditions.push(where(`partOf.${taxonomySection}.${taxonomySubsection}`, 'array-contains', taxonomyName))
    }

    if (order)
      conditions.push(orderBy(order, 'desc'))

    if (published)
      conditions.push(where('published', '==', published === 'true'))

    if (hits)
      conditions.push(limit(hits))

    const q = query(
      collection(firestore, section, subsection, "content"),
      ...conditions,
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => setData([...querySnapshot.docs]));

    return () => unsubscribe();
  }, [section, subsection, taxonomySection, taxonomySubsection, taxonomyName, order, published]);

  const handleCreate = (article) => {
    setFormData(article);
    setFormOpen(true);
  };

  const handleGenerate = (article) => {
    setGenerateFormData(article);
    setGenerateFormOpen(true);
  };

  const handleEdit = (article) => {
    setFormData(article);
    setFormOpen(true);
  };

  const handleRemove = (ref) => {
    setDeleteDocRef(ref);
    setDeleteDialogOpen(true);
  };

  const confirmRemove = () => {
    deleteDoc(doc(firestore, deleteDocRef));
    setDeleteDialogOpen(false);
  };

  const handleSearchSelect = async (selected) => {
    const docRef = doc(firestore, section, subsection, "content", selected.objectID);
    const docData = await getDoc(docRef);

    setFormData(docData);
    setFormOpen(true);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Toolbar>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ width: '100%' }}>
          {search 
            ? <Search
                section={section}
                subsection={subsection} 
                select={handleSearchSelect}
              />
            : <div></div>
          }

          <Stack direction="row">
            <Button
              variant="contained"
              onClick={handleCreate}
            >
              Add
            </Button>

            <Actions
              section={section}
              subsection={subsection}
            />
          </Stack>
        </Stack>
      </Toolbar>
      
      <Container>
        <Stack spacing={2}>
          {data.map((article, index) =>
            <Card
              key={article.id}
              section={section}
              subsection={subsection}
              article={article}
              generate={canGenerate ? handleGenerate : false}
              edit={handleEdit}
              remove={handleRemove}
            />
          )}
        </Stack>
      </Container>

      <ConfirmDialog
        title="Delete"
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onConfirm={confirmRemove}
      >
        Bro, you sure you wanna delete this?
      </ConfirmDialog>

      <ContentForm
        open={formOpen}
        close={() => setFormOpen(false)}
        section={section}
        subsection={subsection}
        data={formData}
        save={() => setFormOpen(false)}
      />

      <GenerateForm
        open={generateFormOpen}
        close={() => setGenerateFormOpen(false)}
        section={section}
        subsection="articles"
        data={generateFormData}
        save={() => setGenerateFormOpen(false)}
      />
    </Box>
  );
};

export default FileExplorer;
