import React, { useState, useEffect } from 'react';
import { firestore } from 'utilities/firebase';
import {
  query,
  collection,
} from 'firebase/firestore'; 
import { useFirestoreQuery } from "@react-query-firebase/firestore";

import Typography from '@mui/material/Typography';

const Source = ({ id }) => {
  const [value, setValue] = useState('');

  const refQuery = query(
    collection(firestore, "news", "sources", "content"),
  );

  const { isLoading, data } = useFirestoreQuery(["news", "sources"], refQuery);

  useEffect(() => {
    if (!isLoading) {
      const dataobj = data.docs.find((obj) => obj.id === id)

      setValue(dataobj.data().title);
    }
  }, [isLoading]);

  return (
    <Typography variant="caption">
      {isLoading
        ? 'Loading'
        : `${value}:`
      } 
    </Typography>
  );
};

export default Source;
