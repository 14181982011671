import React from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// import ImageKitLibrary from 'components/ImageKitLibrary';
import CloudinaryLibrary from 'components/CloudinaryLibrary';
import ImagePreview from 'components/ImagePreview';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ReorderIcon from '@mui/icons-material/Reorder';
import CollectionsIcon from '@mui/icons-material/Collections';

import { EDIT_GALLERYIMAGE, DELETE_GALLERYIMAGE } from '../../Constants';

const Image = ({ editing, source, image, dispatch, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: image.url });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleChange = (e) => 
    dispatch({
      type: EDIT_GALLERYIMAGE,
      value: {
        index: index,
        value: e.target.value,
      },
    });

  const handleDelete = () =>
    dispatch({
      type: DELETE_GALLERYIMAGE,
      value: index,
    });

  const handleInsert = (images) =>
    dispatch({
      type: EDIT_GALLERYIMAGE,
      value: {
        index: index,
        value: {
          url: images[0].url,
          width: images[0].width,
          height: images[0].height,
        },
      },
    });

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      ref={setNodeRef}
      style={style}
    >
      {editing && 
        <IconButton  {...attributes} {...listeners}>
          <ReorderIcon />
        </IconButton>
      }

      <Box sx={{ width: 50 }}>
        <ImagePreview source={source} url={image.url} />
      </Box>

      <TextField
        name="url"
        variant="standard"
        value={image.url}
        onChange={handleChange}
        sx={{ flex: 1 }}
        disabled={source === 'cloudinary'}
        required
      />

      {source === 'cloudinary' &&
        <CloudinaryLibrary key={`gallery-${index}`} onInsert={handleInsert}>
          <IconButton size="small">
            <CollectionsIcon fontSize="small" />
          </IconButton>
        </CloudinaryLibrary>
      }

      {editing && 
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      }
    </Stack>
  );
};

export default Image;
