import React from 'react';

import CloudinaryLibrary from 'components/CloudinaryLibrary';
import ImagePreview from 'components/ImagePreview';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CollectionsIcon from '@mui/icons-material/Collections';

import { ADD_IMAGE, EDIT_IMAGE, DELETE_IMAGE } from '../Constants';

// const arePropsEqual = (prev, next) => prev.data.image.dynamic === next.data.image.dynamic;

const ImageDynamic = ({ data, dispatch }) => {
  const imageType = 'dynamic';

  const handleAdd = () => 
    dispatch({
      type: ADD_IMAGE,
      value: {
        type: imageType,
        value: {
          source: 'cloudinary',
          url: '',
          width: '',
          height: '',
          alt: '',
          author: '',
          author_url: '',
        },
      }
    });

  const dispatchChanges = (field, value) => 
    dispatch({
      type: EDIT_IMAGE,
      value: {
        field: field,
        type: imageType,
        value: value,
      }
    });

  const handleRemove = () =>
    dispatch({
      type: DELETE_IMAGE,
      value: {
        type: imageType,
      },
    });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'source') {
      dispatchChanges('url', '');
      dispatchChanges('width', '');
      dispatchChanges('height', '');
    };

    dispatchChanges(name, value);
  };
  
  const handleInsert = (data) => {
    const image = data.assets[0];

    dispatchChanges('source', 'cloudinary');
    dispatchChanges('url', `/v${image.version}/${image.public_id}`);
    dispatchChanges('width', image.width);
    dispatchChanges('height', image.height);
  }

  return (
    <Stack component={Paper} spacing={2} sx={{ padding: (t) => t.spacing(2) }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>
          Dynamic image
        </Typography>

        <IconButton
          onClick={data.image && data.image.dynamic ? handleRemove : handleAdd}
          size="small"
        >
          {data.image && data.image.dynamic ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Stack>

      {data.image && data.image.dynamic &&
        <React.Fragment>
          <Divider />

          <Stack
            component={Paper}
            direction={{
              xs: "column",
              sm: "row",
            }}
            spacing={2}
          >
            <Box sx={{ position: 'relative', flex: 1 }}>
              <ImagePreview source={data.image[imageType].source} url={data.image[imageType].url} />

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  position: 'absolute',
                  right: (t) => t.spacing(2),
                  bottom: (t) => t.spacing(2),
                }}
              >
                <CloudinaryLibrary onInsert={handleInsert}>
                  <IconButton
                    sx={{
                      backgroundColor: '#000',
                      color: '#fff',
                    }}
                    size="small"
                  >
                    <CollectionsIcon fontSize="small" />
                  </IconButton>
                </CloudinaryLibrary>
              </Stack>
            </Box>	

            <Stack spacing={2} sx={{ flex: 1 }}>
              <Select
                name="source"
                value={data.image[imageType].source || 'cloudinary'}
                label="Source"
                variant="standard"
                onChange={handleChange}
              >
                <MenuItem value="external">External</MenuItem>
                <MenuItem value="cloudinary">Cloudinary</MenuItem>
                <MenuItem value="unsplash">Unsplash</MenuItem>
                <MenuItem value="twitter">Twitter</MenuItem>
              </Select>

              <TextField
                name="url"
                label="URL"
                variant="standard"
                value={data.image[imageType].url}
                onChange={handleChange}
                disabled={data.image[imageType].source === 'cloudinary'}
                required
              />

              <Stack direction="row" spacing={2}>
                <TextField
                  name="width"
                  label="Width"
                  variant="standard"
                  value={data.image[imageType].width}
                  onChange={handleChange}
                  disabled={data.image[imageType].source === 'cloudinary'}
                  required
                />

                <TextField
                  name="height"
                  label="Height"
                  variant="standard"
                  value={data.image[imageType].height}
                  onChange={handleChange}
                  disabled={data.image[imageType].source === 'cloudinary'}
                  required
                />
              </Stack>

              <TextField
                name="alt"
                label="Alt description"
                variant="standard"
                value={data.image[imageType].alt}
                onChange={handleChange}
                required
              />
            </Stack>
          </Stack>

          <TextField
            name="author"
            label="Author"
            variant="standard"
            value={data.image[imageType].author ? data.image[imageType].author : ''}
            onChange={handleChange}
          />

          <TextField
            name="author_url"
            label="Author URL"
            variant="standard"
            value={data.image[imageType].author_url ? data.image[imageType].author_url : ''}
            onChange={handleChange}
          />
        </React.Fragment>
      }
    </Stack>
  );
}; // , arePropsEqual);

export default ImageDynamic;
