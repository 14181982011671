import React, { useState, useEffect } from 'react';
import slugify from 'slugify';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';

const generateId = (value) => slugify(value, {
  lower: true,
  strict: true,
});

const Form = ({ id, data, submit, cancel }) => {
  const [state, setState] = useState(data);

  useEffect(() => setState(data), [data]);

  const handleIdChange = (e) =>
    setState(prevState => ({
      ...prevState,
      id: generateId(e.target.value),
    }));

  const handleChange = (e) =>
    setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    submit(state);
  };

  const handleCancel = () => cancel();

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          id="id"
          name="id"
          label="ID"
          value={state.id}
          onChange={handleIdChange}
          variant="standard"
          disabled={id ? true : false}
          required
          fullWidth
        /> 

        <TextField
          id="title"
          name="title"
          label="title"
          value={state.title}
          onChange={handleChange}
          variant="standard"
          required
          fullWidth
        /> 

        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {id ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Stack>
    </form>
  );
};

export default Form;
