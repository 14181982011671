import React, { useContext } from 'react';
import EditorContext from '../Context';
import TextField from '@mui/material/TextField';
import { SET_DATA } from '../Constants';

const Note = () => {
  const { state, dispatch } = useContext(EditorContext);

  const handleChange = (e) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: e.target.name,
        value: e.target.value,
      },
    });

  return (
    <TextField
      name="note"
      label="Note"
      variant="standard"
      value={state.note ?? ''}
      onChange={handleChange}
      multiline
    />
  );
};

export default Note;
