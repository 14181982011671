import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SET_DATA } from '../Constants';

const arePropsEqual = (prev, next) =>
  prev.data.toc === next.data.toc;

const TOC = React.memo(({ data, dispatch }) => {
  const [ hasTOC, setHasTOC ] = useState(false);

  useEffect(() => {
    if (data.toc !== undefined) {
      setHasTOC(data.toc);
    }
  }, [data.toc]);

  const handleChange = (e) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: 'toc',
        value: e.target.checked,
      },
    });

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={hasTOC}
            onChange={handleChange}
          />
        }
        label="Table of Contents"
      />
    </FormGroup>
  );
}, arePropsEqual);

export default TOC;
