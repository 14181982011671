import React from 'react';
import { firestore } from 'utilities/firebase';
import { doc, updateDoc } from "firebase/firestore";
import { useFirestoreDocumentDeletion } from "@react-query-firebase/firestore";

import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { green } from '@mui/material/colors';

const Published = ({ data }) => {
  const docRef = doc(firestore, data.ref.path);
  const mutation = useFirestoreDocumentDeletion(docRef);

  const publish = () =>
    updateDoc(docRef, {
      published: !data.data().published
    })

  const remove = () => mutation.mutate();

  return (
    <IconButton
      aria-label="published"
      onClick={!data.data().published ? publish : remove}
    >
      {data.data().published
        ? <VisibilityIcon fontSize="small" sx={{ color: green[500] }} />
        : <VisibilityOffIcon fontSize="small" color="disabled" />}
    </IconButton>
  );
};

export default Published;
