import React, { useEffect, useContext } from 'react';

import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";

import { HeaderContext } from 'store/header';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const DashboardPage = () => {
  const { setTitle } = useContext(HeaderContext);

  useEffect(() => setTitle('Dashboard'), []);

  // Featured content
  const updateFeaturedToday = () => httpsCallable(functions, 'updateFeaturedContent')()

  // Test OpenAI
  const getOpenAIResponse = httpsCallable(functions, 'getOpenAIResponse');
  
  const promptOpenAI = () => {
    getOpenAIResponse({ prompt: "Hi OpenAI!" })
      .then((result) => {
        alert(result.data.reply.content);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>Dashboard</h1>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <h2>Random</h2>

              <Stack spacing={2}>
                <Button variant="contained" onClick={updateFeaturedToday}>
                  Generate Featured Today Content
                </Button>
              </Stack>

              <Stack spacing={2}>
                <Button variant="contained" onClick={promptOpenAI}>
                  Test OpenAI
                </Button>
              </Stack>
            </Stack>

            {/*
            <Stack>
              <h2>Sitemaps</h2>

              <Stack spacing={2}>
                <Button variant="contained" onClick={generateSitemaps}>
                  Generate Sitemaps
                </Button>
              </Stack>
            </Stack>
            */}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default DashboardPage;
