import React from 'react';

import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";

const PickBest = () => {
  const handleClick = () => httpsCallable(functions, 'triggerDealsPickBest')();

  return (
    <div onClick={handleClick}>
      Pick Best
    </div>
  );
};

export default PickBest;
