import React, { useState } from 'react';

import List from './List';

import Aggregate from './Actions/Aggregate';
import Clean from './Actions/Clean';
import EditIndexPage from './Actions/editIndexPage';
import ContentForm from 'forms/Content';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Toolbar from '@mui/material/Toolbar';
import AddBoxIcon from '@mui/icons-material/AddBox';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const TabPanel = ({ children, value, index, ...other }) =>
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ marginTop: 2 }}>
        {children}
      </Box>
    )}
  </div>

const NewsArticles = () => {
  const [ tab, setTab ] = useState(0);
  // const { setTitle } = useContext(HeaderContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleTabChange = (e, tab) => setTab(tab);

  const [ formOpen, setFormOpen ] = useState(false);
  const [ formData, setFormData ] = useState();

  const isProd = process.env.REACT_APP_ENV === 'live';

  // const [ addToOpen, setAddToOpen ] = useState(false);
  // const [ addToData, setAddToData ] = useState();
  //
  const handleAdd = () => {
    setFormData(null);
    setFormOpen(true);
  };

  const handleEdit = (article) => {
    setFormData(article);
    setFormOpen(true);
  };

  /*
  const handleAddTo = (id, data) => {
    setAddToData({ id, data });
    setAddToOpen(true);
  };
  */

  // useEffect(() => setTitle('News'), []);

  return (
    <>
      <Toolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ width: '100%' }}
        >
          <p>News</p>

          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<AddBoxIcon />}
              onClick={handleAdd}
            >
             Add
            </Button>

            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="news-actions"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <MenuItem>
                <Aggregate />
              </MenuItem>  
              <MenuItem>
                <Clean />
              </MenuItem>  
              <MenuItem>
                <EditIndexPage />
              </MenuItem>  
            </Menu>
          </Stack>
        </Stack>
      </Toolbar>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="All" id="all" />
          <Tab label="Published" id="published" />
          <Tab label="Top" id="top" />
          <Tab label="Featured" id="featured" />
        </Tabs>
      </Box>

      <Container maxWidth="md">
        <TabPanel value={tab} index={0}>
          <List
            edit={handleEdit}
            draggable={true}
            section="news"
            subsection="articles"
            order="publishedOn"
            hits={20}
          />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <List
            edit={handleEdit}
            draggable={false}
            section="news"
            subsection="articles"
            order="publishedOn"
            published="true"
            hits={20}
          />
        </TabPanel>

        <TabPanel value={tab} index={2}>
          <List
            edit={handleEdit}
            draggable={false}
            section="news"
            subsection="articles"
            order="publishedOn"
            published="true"
            taxonomySection="news"
            taxonomySubsection="collections"
            taxonomyName={isProd ? 'VH9EnY7mIYIdCs18bIeS' : 'ZsyawIQkSQDYF5h940k6'}
            hits={20}
          />
        </TabPanel>

        <TabPanel value={tab} index={3}>
          <List
            edit={handleEdit}
            draggable={false}
            section="news"
            subsection="articles"
            order="publishedOn"
            published="true"
            taxonomySection="news"
            taxonomySubsection="collections"
            taxonomyName={isProd ? 'eFMfsnOnYNgIcVQ8K6PV' : 'L73mZi3YYgxQYEeXMOSB'}
            hits={20}
          />
        </TabPanel>
      </Container>

      <ContentForm
        open={formOpen}
        close={() => setFormOpen(false)}
        section="news"
        subsection="articles"
        data={formData}
        save={() => setFormOpen(false)}
      />
    </>
  );
};

export default NewsArticles;
