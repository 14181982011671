import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, connectAutoComplete } from 'react-instantsearch-dom';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY,
);

const SearchBox = ({
  hits,
  currentRefinement,
  refine,
  onSelect
}) =>
  <Autocomplete
    options={hits}
    onChange={(e,val) => onSelect(val)}
    input={currentRefinement}
    onInputChange={(e) => refine(e.target.value)}
    getOptionLabel={(option) => option.title}
    renderInput={(params) => 
      <TextField
        {...params}
        placeholder="Search..."
        InputProps={{
          ...params.InputProps,
          type: 'search',
        }}
        variant="standard"
        fullWidth
      />
    }
    autoHighlight
    fullWidth
  />

const Search = ({ section, subsection, select }) => {
  const CustomAutocomplete = connectAutoComplete(SearchBox);
  const handleSelect = (hit) => select(hit);

  return (
    <InstantSearch
      indexName={`${section}_${subsection}`}
      searchClient={searchClient}
    >
      <Configure
        hitsPerPage={10}
      />

      <CustomAutocomplete onSelect={handleSelect} />
    </InstantSearch>
  );
};

export default Search;
