import React, { useState, useEffect, createContext } from "react";
import { auth, firestore } from 'utilities/firebase';
import { doc, onSnapshot } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';

const UserContext = createContext();

const UserProvider = (props) => {
  const [user, loading, error] = useAuthState(auth);

  const [state, setState] = useState({
    userLoading: true,
    userError: false,
    userId: null,
    userData: null,
  });

  const setUser = (value) => setState(prevState => ({
    ...prevState,
    ...value,
  }));

  useEffect(() => {
    if (!loading) {
      if (user) {
        const docRef = doc(firestore, 'users', user.uid);
        const unsubscribe = onSnapshot(docRef, (doc) => {
          setState(prevState => ({
            ...prevState,
            userLoading: false,
            userError: false,
            userId: user.uid,
            userData: doc.data(),
            setUser,
          }));
        });

        return () => unsubscribe();

      } else {
        setState({
          userLoading: false,
          userError: false,
          userId: null,
          userData: null,
        });
      };
    };
  }, [loading, user, error]);

  return (
    <UserContext.Provider value={state}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
