import React from 'react';
import Avatar from '@mui/material/Avatar';

const formatUrl = (source, url) => {
 	let fullUrl = null;
  
  // Image is 640 which is the size smallest size used by Next.js
  // To prevent creating too many image derivates

  switch (source) {
    case 'external':
      fullUrl = url;
      break;
    case 'cloudinary':
      fullUrl = `https://res.cloudinary.com/dev-and-gear/image/upload/w_640,q_auto,f_auto/${url}`;
      break;
    case 'imagekit':
      fullUrl = `https://ik.imagekit.io/devandgear/tr:w-640,q-80,pr-true${url}`;
      break;
    case 'unsplash':
      fullUrl = `https://source.unsplash.com/${url}/480`;
      break;
    case 'twitter':
      fullUrl = `https://res.cloudinary.com/dev-and-gear/image/twitter_name/q_auto,f_auto/${url}`;
      break;
    default:
      fullUrl = url;
      break;
  }

  return fullUrl;
};

const ImagePreview = React.memo(({ source, url }) =>
  <Avatar src={source && url ? formatUrl(source, url) : ''} variant="square" sx={{ width: '100%', height: 'auto' }} />
);

export default ImagePreview;
