import React, { useState, useEffect } from 'react';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import AddSeller from './AddSeller';
import Seller from './Seller';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Actions
import { SET_PRIMARY_SELLER, MOVE_SELLER } from '../../Constants';

const Sellers = ({ data, dispatch }) => {
  const [ editing, setEditing ] = useState(false);
  const { seller, sellers } = data;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    if (Object.keys(sellers).length > 1)
      setEditing(false);
	}, [Object.keys(sellers).length]);

  const isPrimary = (id) => seller.store.id === id;

  const setPrimary = (id) =>
    dispatch({
      type: SET_PRIMARY_SELLER,
      value: id,
    });

  return (
    <Box
      component={Paper}
      variant="outlined"
      sx={{ padding: 2 }}
    >
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="body1">
            Sellers
          </Typography>

          <Stack direction="row" spacing={2}>
            {Object.keys(sellers).length > 1 &&
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setEditing(!editing)}
              >
                {editing ? 'Done' : 'Edit'}
              </Button>
            }
          </Stack>
        </Stack>

				<DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(e) =>
            dispatch({ type: MOVE_SELLER, value: e })
          }
        >
          <SortableContext
            items={Object.keys(sellers)}
            strategy={verticalListSortingStrategy}
          >
            {Object.keys(sellers)
              .sort((a, b) => sellers[a].weight > sellers[b].weight ? 1 : -1)
              .map((key) => {
                const primary = isPrimary(key);

                return (
                  <Seller
                    key={key}
                    id={key}
                    data={sellers[key]}
                    primary={primary}
                    setPrimary={() => setPrimary(key)}
                    dispatch={dispatch}
                    editing={Object.keys(sellers).length > 1 && editing}
                  />
                )
              })
            }
					</SortableContext>
        </DndContext>

        <AddSeller
          sellers={sellers}
          dispatch={dispatch}
        />

      </Stack>
    </Box>
  );
};

export default Sellers;
