import React, { useState } from 'react';

import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

const Scraper = () => {
  const [ loading, setLoading ] = useState(false);
  const [ url, setUrl ] = useState('');

  const handleClick = () => {
    setLoading(true);

    httpsCallable(functions, 'scrapeDealsFromWebpage')({
        url: url
      })
      .then(() => setLoading(false));
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ flex: 1 }}
    >
      <TextField
        placeholder="Scrape URL"
        variant="outlined"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        variant="standard"
        fullWidth
      /> 

      <IconButton
        aria-label="published"
        onClick={handleClick}
        disabled={loading}
      >
        {loading
          ? <CircularProgress size={18} />
          : <ArrowCircleDownIcon fontSize="small" />}
      </IconButton>
    </Stack>
  );
};

export default Scraper;
