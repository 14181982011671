import React from 'react';
import TextField from '@mui/material/TextField';
import { SET_DATA } from '../Constants';

const arePropsEqual = (prev, next) => prev.data.slug === next.data.slug;

const Slug = React.memo(({ data, dispatch }) => {
  const handleChange = (e) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: e.target.name,
        value: e.target.value,
      },
    });

  return (
    <TextField
      name="slug"
      label="Slug"
      variant="standard"
      value={data.slug}
      onChange={handleChange}
      multiline
      required
    />
  );
}, arePropsEqual);

export default Slug;
