import React from 'react';

import { useDroppable } from "@dnd-kit/core";

import Paper from '@mui/material/Paper';
import Card from './Card';

const CardContainer = ({ data, edit, addTo, todo, disabled }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: data.id,
    disabled,
  });

  return (
    <Paper
      ref={setNodeRef}
      elevation={isOver ? 24 : 1}
    >
      <Card
        data={data}
        edit={edit}
        addTo={addTo}
        todo={todo}
        disabled={disabled}
      />
    </Paper>
  );
};

export default CardContainer;
