import React, { useState } from 'react';
import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const Clean = () => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    
    httpsCallable(functions, 'manualNewsCleaner')()
      .then(() => setLoading(false));
  };

  return (
    <Button
      endIcon={loading && <CircularProgress size={18} />}
      onClick={handleClick}
    >
      Clean
    </Button>
  );
};

export default Clean;
