import React, { useState } from 'react';

import {
  DndContext,
  closestCenter,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import ImageKitLibrary from 'components/ImageKitLibrary';
import CloudinaryLibrary from 'components/CloudinaryLibrary';

import Image from './Image';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CollectionsIcon from '@mui/icons-material/Collections';

import {
  SET_GALLERY,
  ADD_GALLERYIMAGE,
  MOVE_GALLERYIMAGE,
} from '../../Constants';

const Form = ({ type, data, dispatch }) => {
  const [ editing, setEditing ] = useState(false);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const dispatchChanges = (field, value) => 
    dispatch({
      type: SET_GALLERY,
      value: {
        field: field,
        value: value,
      }
    });

  const handleAltChange = (e) => {
    const { name, value } = e.target;

    dispatchChanges(name, value);
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;

    dispatchChanges('items', [
      {
        url: '',
        width: 0,
        height: 0,
      },
      {
        url: '',
        width: 0,
        height: 0,
      },
      {
        url: '',
        width: 0,
        height: 0,
      },
    ]);

    dispatchChanges(name, value);
  };

  const handleInsert = (images) => dispatchChanges('items', images);

  const handleAdd = () =>
    dispatch({
      type: ADD_GALLERYIMAGE,
      value: {
        url: '',
        width: 0,
        height: 0,
      },
    })

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Select
          name="source"
          value={data.gallery.source}
          label="Source"
          variant="standard"
          onChange={handleTypeChange}
          sx={{ flex: 1 }}
        >
          <MenuItem value="external">External</MenuItem>
          <MenuItem value="cloudinary">Cloudinary</MenuItem>
          <MenuItem value="imagekit">ImageKit</MenuItem>
          <MenuItem value="unsplash">Unsplash</MenuItem>
        </Select>

        <Stack direction="row" alignItems="center" spacing={1}>
          {data.gallery.source === 'cloudinary'
            ? <CloudinaryLibrary key="bulk-upload" onInsert={handleInsert}>
                <IconButton size="small">
                  <CollectionsIcon fontSize="small" />
                </IconButton>
              </CloudinaryLibrary>
            : <ImageKitLibrary key="gallery-upload-imagekit" onInsert={handleInsert}>
                <IconButton>
                  <CollectionsIcon fontSize="small" />
                </IconButton>
              </ImageKitLibrary>
          }

          <IconButton
            onClick={() => setEditing(!editing)}
            size="small"
          >
            {editing ? <DoneIcon fontSize="small" /> : <EditIcon fontSize="small" />}
          </IconButton>

          <IconButton
            onClick={handleAdd}
            size="small"
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(e) =>
          dispatch({ type: MOVE_GALLERYIMAGE, value: e })
        }
      >
        <SortableContext
          items={data.gallery.items}
          strategy={verticalListSortingStrategy}
        >
          {data.gallery.items.map((image, index) =>
            <Image
              key={`${index}-${image.url}`}
              editing={editing} 
              source={data.gallery.source}
              image={image}
              dispatch={dispatch}
              index={index}
            />
          )}
        </SortableContext>
      </DndContext>

      <TextField
        name="alt"
        label="Alt description"
        variant="standard"
        value={data.gallery.alt ? data.gallery.alt : ''}
        onChange={handleAltChange}
        required
      />
    </>
  );
};

export default Form;
