import React, { useState, useEffect } from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { firestore } from 'utilities/firebase';
import { doc, getDoc } from 'firebase/firestore'; 

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ReorderIcon from '@mui/icons-material/Reorder';

const Item = ({ index, id, remove, editing }) => {
  const [ product, setProduct ] = useState({});

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: id });

  useEffect(async () => {
    const docRef = doc(firestore, 'products', 'articles', "content", id);
    const docSnap = await getDoc(docRef);

    setProduct({
      id: id,
      title: docSnap.data().title,
    });
  }, []);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleRemove = () => remove(index)

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      ref={setNodeRef}
      style={style}
    >
      {editing && 
        <IconButton {...attributes} {...listeners}>
          <ReorderIcon />
        </IconButton>
      }

      <TextField
        value={product.title || 'Loading'}
        variant="standard"
        fullWidth
        disabled
      />

      {editing && 
        <IconButton onClick={handleRemove}>
          <DeleteIcon />
        </IconButton>
      }
    </Stack>
  );
};

export default Item;
