import React, { useEffect, useState } from 'react';
import { DateTimePicker }  from '@mui/x-date-pickers/DateTimePicker';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { SET_DATA } from '../../Constants';

// const arePropsEqual = (prev, next) => prev.data.title === next.data.title;

const PublishedOn = ({ data, dispatch }) => {
  const [ publishDate, setPublishDate ] = useState();

  useEffect(() => {
    data.publishedOn?.seconds 
      ? setPublishDate(new Date(data.publishedOn.seconds * 1000))
      : setPublishDate(data.publishedOn);
  }, [data.publishedOn]);

  const handleChange = (value) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: 'publishedOn',
        value: value,
      },
    });

  return (
    <Stack direction="row" spacing={2}>
      <DateTimePicker
        label="Publish on"
        value={publishDate}
        ampm={false}
        onChange={handleChange}
        // disabled={data.published ? true : false}
        renderInput={(props) =>
          <TextField variant="standard" {...props} sx={{ flex: 1 }} />
        }
      />
    </Stack>
  );
}; //, arePropsEqual);

export default PublishedOn;
