import React, { useState } from 'react';
import { Outlet } from "react-router";
import { Link } from 'react-router-dom'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Header from 'components/Header';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import Toolbar from '@mui/material/Toolbar';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SellIcon from '@mui/icons-material/Sell';
import LaptopIcon from '@mui/icons-material/Laptop';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import DescriptionIcon from '@mui/icons-material/Description';
import WebIcon from '@mui/icons-material/Web';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ListIcon from '@mui/icons-material/List';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import GroupsIcon from '@mui/icons-material/Groups';

const drawerWidth = 240;

const menu = [
  {
    label: 'Dashboard',
    url: '/',
    icon: <DashboardIcon />,
  },
  {
    label: 'Affiliates',
    url: '/affiliates',
    icon: <BusinessCenterIcon />,
  },
  {
    label: 'Ads',
    url: '/ads',
    icon: <SellIcon />,
  },
  {
    id: 'news',
    label: 'News',
    url: '/news',
    icon: <NewspaperIcon />,
    collapsible: true,
    submenu: [
      {
        label: 'To-Do',
        url: '/news/todos',
        icon: <ListIcon />,
      },
      {
        label: 'Articles',
        url: '/news/articles',
        icon: <ArticleIcon />,
      },
      {
        label: 'Collections',
        url: '/news/collections',
        icon: <ArticleIcon />,
      },
      {
        label: 'Topics',
        url: '/news/topics',
        icon: <ArticleIcon />,
      },
      {
        label: 'Categories',
        url: '/news/categories',
        icon: <ArticleIcon />,
      },
      {
        label: 'Sources',
        url: '/news/sources',
        icon: <ArticleIcon />,
      },
    ]
  },
  {
    id: 'deals',
    label: 'Deals',
    url: '/deals',
    icon: <ShoppingBagIcon />,
    collapsible: true,
    submenu: [
      {
        label: 'To-Do',
        url: '/deals/todos',
        icon: <ListIcon />,
      },
      {
        label: 'Articles',
        url: '/deals/articles',
        icon: <ListIcon />,
      },
      {
        label: 'Products',
        url: '/deals/products/active',
        icon: <ListIcon />,
      },
      {
        label: 'Collections',
        url: '/deals/collections',
        icon: <ListIcon />,
      },
      {
        label: 'Stores',
        url: '/deals/stores',
        icon: <ListIcon />,
      },
    ],
  },
  {
    id: 'products',
    label: 'Products',
    url: '/products',
    icon: <HeadphonesIcon />,
    collapsible: true,
    submenu: [
      {
        label: 'To-Do',
        url: '/products/todos',
        icon: <ListIcon />,
      },
      {
        label: 'Articles',
        url: '/products/articles',
        icon: <ListIcon />,
      },
      {
        label: 'Brands',
        url: '/products/brands',
        icon: <ListIcon />,
      },
      {
        label: 'Collections',
        url: '/products/collections',
        icon: <ListIcon />,
      },
      {
        label: 'Categories',
        url: '/products/categories',
        icon: <ListIcon />,
      },
    ],
  },
  {
    id: 'setups',
    label: 'Setups',
    url: '/setups',
    icon: <DesktopMacIcon />,
    collapsible: true,
    submenu: [
      {
        label: 'To-Do',
        url: '/setups/todos',
        icon: <ListIcon />,
      },
      {
        label: 'Articles',
        url: '/setups/articles',
        icon: <ListIcon />,
      },
      {
        label: 'Collections',
        url: '/setups/collections',
        icon: <ListIcon />,
      },
    ],
  },
  {
    id: 'blog',
    label: 'Blog',
    url: '/blog',
    icon: <ArticleIcon />,
    collapsible: true,
    submenu: [
      {
        label: 'To-Do',
        url: '/blog/todos',
        icon: <ListIcon />,
      },
      {
        label: 'Articles',
        url: '/blog/articles',
        icon: <ListIcon />,
      },
      {
        label: 'Categories',
        url: '/blog/categories',
        icon: <ListIcon />,
      },
    ],
  },
  {
    label: 'Pages',
    url: '/pages',
    icon: <WebIcon />,
  },
  {
    label: 'Team',
    url: '/team',
    icon: <GroupsIcon />,
  },
];

const Layout = (props) => {
  const { window } = props;
  const [ open, setOpen ] = useState({
    news: false,
    products: false,
    setups: false,
    blog: false,
    deals: false,
  });

	const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const handleOpen = (section) => setOpen(prevState => ({
    ...prevState,
    [section]: !prevState[section],
  }));

  const drawer = (
    <div>
      <Toolbar />

      <Divider />

      <List disablePadding>
        {menu.map((item, index) =>
          <React.Fragment
            key={index}
          >
            <ListItemButton
              component={item.collapsible ? null : Link}
              to={item.collapsible ? null : item.url}
              onClick={() => item.collapsible ? handleOpen(item.id) : handleDrawerToggle()}
              sx={{ width: '100%' }}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>

              <ListItemText primary={item.label} />

              {item.collapsible &&
                <>
                  {open[item.id]
                    ? <ExpandLess />
                    : <ExpandMore />
                  }
                </>
              }
            </ListItemButton>

            {item.collapsible &&
              <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding> 
                  {item.submenu.map((sub, index) => 
                    <ListItemButton
                      key={`sub-${index}`}
                      component={Link} to={sub.url} sx={{ pl: 4 }}
                      onClick={handleDrawerToggle}
                    >
                      <ListItemIcon>
                        {sub.icon}
                      </ListItemIcon>

                      <ListItemText primary={sub.label} />
                    </ListItemButton>
                  )}
                </List>
              </Collapse>
            }
          </React.Fragment>
        )}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Header>
         	<IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton> 
        </Header>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={container}
          variant={isMobile ? "temporary" : 'permanent'}
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box component="main" sx={{ width: '100%', flexGrow: 1 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
