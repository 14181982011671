import React from 'react';
import TextField from '@mui/material/TextField';
import { SET_DATA } from '../Constants';

const arePropsEqual = (prev, next) => prev.data.weight === next.data.weight;

const Weight = React.memo(({ data, dispatch }) => {
  const handleChange = (e) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: e.target.name,
        value: e.target.value,
      },
    });

  return (
    <TextField
      name="weight"
      label="Weight"
      variant="standard"
      value={data.weight}
      onChange={handleChange}
      type="number"
      fullWidth
    /> 
  );
}, arePropsEqual);

export default Weight;
