import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';

import { UserProvider } from '../store/user';
import { HeaderProvider } from '../store/header';

/*
// News
import { NewsAllProvider } from '../store/news/all';
import { NewsPublishedProvider } from '../store/news/published';
import { NewsTopProvider } from '../store/news/top';
import { NewsFeaturedProvider } from '../store/news/featured';

// Deals
import { DealsAllProvider } from '../store/deals/all';
import { DealsBestProvider } from '../store/deals/best';
import { DealsHotProvider } from '../store/deals/hot';
import { DealsExpiredProvider } from '../store/deals/expired';
import { DealsStoresProvider } from '../store/deals/stores';
*/

import Loading from '../components/Loading';
import Routes from './Routes';

const theme = createTheme();

const App = () =>
  <ThemeProvider theme={theme}>
    <UserProvider>
      <Loading>
        <HeaderProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </HeaderProvider>
      </Loading>
    </UserProvider>
  </ThemeProvider>

export default App;

