import React from 'react';
import ReactDOM from 'react-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './views/App';

console.log('create-react-app env:', process.env.NODE_ENV)
console.log('project:', process.env.REACT_APP_ENV)

const queryClient = new QueryClient();

// render the App component to our document root with React
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <App />
    </LocalizationProvider>
  </QueryClientProvider>
  , document.getElementById('root')
);
