import React, { useState } from 'react';

import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";

import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

const ScrapeForDeals = ({ data }) => {
  const [ loading, setLoading ] = useState(false);

  const handleClick = () => {
    setLoading(true);

    httpsCallable(functions, 'scrapeDealsFromWebpage ')({ url: data.data().link })
      .then(() => setLoading(false));
  }

  return (
    <IconButton
      aria-label="published"
      onClick={handleClick}
      disabled={loading}
    >
      {loading
        ? <CircularProgress size={14} />
        : <LocalGroceryStoreIcon fontSize="small" />}
    </IconButton>
  );
};

export default ScrapeForDeals;
