import React from 'react';

import Form from './Form';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { SET_DATA, DELETE_DATA } from '../../Constants';

const Gallery = ({ type, data, dispatch }) => {

  const handleAdd = () => 
    dispatch({
      type: SET_DATA,
      value: {
        field: 'gallery',
        value: {
          source: 'cloudinary',
          alt: '',
          items: [
            {
              url: '',
              width: 0,
              height: 0,
            }
          ],
        },
      }
    });

  const handleRemove = () =>
    dispatch({
      type: DELETE_DATA,
      value: 'gallery',
    });

  return (
    <Stack component={Paper} spacing={2} sx={{ padding: (t) => t.spacing(2) }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>
          Gallery
        </Typography>
    
        <IconButton
          onClick={data.gallery ? handleRemove : handleAdd}
          size="small"
        >
          {data.gallery ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Stack>

      {data.gallery &&
        <React.Fragment>
          <Divider />

          <Form type={type} data={data} dispatch={dispatch} />
        </React.Fragment>
      }
    </Stack>
  );
};

export default Gallery;
