import React, { useState, useEffect } from 'react';

import { firestore } from 'utilities/firebase';
import { doc, collection, setDoc, addDoc } from 'firebase/firestore'; 

import Form from './Form';

const initalData = {
  id: '',
  title: '',
};

const StoreForm = ({ id, data, submit, close }) => {
  const [ state, setState ] = useState(initalData);

  useEffect(() => {
    data
      ? setState(data)
      : setState(initalData);
  }, []);

  const create = async (createdData) => {
    const colRef = collection(firestore, 'deals', 'stores', 'content');
    const id = await addDoc(colRef, createdData);

    return {
      ...createdData,
    }
  };

  const update = async (updatedData) => {
    const docRef = doc(firestore, 'deals', 'stores', 'content', id);

    await setDoc(docRef, updatedData);

    return updatedData;
  };

  const handleSubmit = (newData) => {
    id
      ? update(newData).then(() => close())
      : create(newData).then(() => submit({ ...newData }))
  }

  const handleCancel = () => close();

  return (
    <Form
      id={id}
      data={state}
      submit={handleSubmit}
      cancel={handleCancel}
    />
  );
};

export default StoreForm;
