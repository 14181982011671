import React from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { SET_DATA } from '../Constants';

const Feed = ({ data, dispatch }) => {

  const handleChange = (e) => {
    dispatch({
      type: SET_DATA,
      value: {
        field: 'feed',
        value: {
          ...data.feed,
          [e.target.name]: e.target.value
        },
      },
    });
  };

  return (
    <Stack
      component={Paper}
      spacing={2}
      sx={{ padding: (theme) => theme.spacing(2) }}
    >
      <Typography variant="subtitle">
        Feed
      </Typography>

      <FormControl
        fullWidth
      >
        <InputLabel id="active-select-label">
          Active
        </InputLabel>

        <Select
          labelId="active-select-label"
          id="active"
          name="active"
          value={data.feed.active}
          label="Active"
          onChange={handleChange}
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      </FormControl> 

      <FormControl
        fullWidth
      >
        <InputLabel id="demo-simple-select-label">
          Auto publish
        </InputLabel>

        <Select
          labelId="autopublish"
          id="autopublish"
          name="autopublish"
          value={data.feed.autopublish}
          label="Auto publish"
          onChange={handleChange}
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      </FormControl> 

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          Type
        </InputLabel>

        <Select
          labelId="type"
          id="type"
          name="type"
          value={data.feed.type}
          label="Type"
          onChange={handleChange}
        >
          <MenuItem value="rss">RSS</MenuItem>
          <MenuItem value="atom">ATOM</MenuItem>
          <MenuItem value="json">JSON</MenuItem>
        </Select>
      </FormControl> 

      <TextField
        id="url"
        name="url"
        label="URL"
        variant="outlined"
        value={data.feed.url}
        onChange={handleChange}
        fullWidth
      /> 
    </Stack>
  );
};

export default Feed;
