import React, { useState } from 'react';

import Link from './Link';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  SET_DATA,
  DELETE_DATA,
  ADD_LINK,
  DELETE_LINK,
} from '../../Constants';

const Links = React.memo(({ data, dispatch }) => {
  const [ editing, setEditing ] = useState(false);

  const handleAddLinks = () => 
    dispatch({
      type: SET_DATA,
      value: {
        field: 'links',
        value: [{
          source: '',
          url: '',
        }],
      }
    });

  const handleRemoveLinks = () =>
    dispatch({
      type: DELETE_DATA,
      value: 'links',
    });

  const handleAdd = () =>
    dispatch({
      type: ADD_LINK,
      value: {
        source: '',
        url: '',
      },
    });

  const handleDelete = (index) =>
    dispatch({
      type: DELETE_LINK,
      value: index,
    });

  return (
    <Stack component={Paper} spacing={2} sx={{ padding: (t) => t.spacing(2) }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>
          Links
        </Typography>

        <IconButton
          onClick={data.links ? handleRemoveLinks : handleAddLinks}
          size="small"
        >
          {data.links ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Stack>

      {data.links &&
        <React.Fragment>
          <Divider />

          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Typography>
              Manage Links
            </Typography>

            <Stack direction="row" spacing={1}>
              <IconButton onClick={() => setEditing(!editing)} size="small">
                {editing ? <DoneIcon fontSize="small" /> : <EditIcon fontSize="small" />}
              </IconButton>

              <IconButton onClick={handleAdd} size="small">
                <AddIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>

          {data.links.map((link, index) =>
            <Stack
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Link
                data={link}
                dispatch={dispatch}
                index={index}
              />

              {editing && 
                <IconButton onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            </Stack>
          )}
        </React.Fragment>
      }
    </Stack>
  );
});

export default Links;
