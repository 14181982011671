import React, { useContext } from 'react';
import { UserContext } from '../store/user';

import CircularProgress from '@mui/material/CircularProgress';

const Loading = ({ children }) => {
  const { userLoading } = useContext(UserContext);

  if (userLoading)
    return (
      <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
      </div>
    );

  return children
};

export default Loading;
