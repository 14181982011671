// import Firebase from 'firebase/app';
import { initializeApp, getApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const dbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
}

const app = initializeApp(dbConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(getApp());

// Dev env for functions
if (process.env.NODE_ENV === 'development')
  connectFunctionsEmulator(functions, 'localhost', 5001);

export { app, auth, firestore, functions };
