const image = () => {
  return {
    source: 'cloudinary',
    url: '',
    width: '',
    height: '',
    alt: '',
    author: '',
    author_url: '',
  };
};

const cover = () => {
  return {
    source: 'cloudinary',
    url: '',
    width: '',
    height: '',
    alt: '',
    author: '',
    author_url: '',
  };
};

const gallery = () => {
  return {
    source: 'cloudinary',
    alt: '',
    items: [
      {
        url: '',
        width: 0,
        height: 0,
      }, {
        url: '',
        width: 0,
        height: 0,
      }, {
        url: '',
        width: 0,
        height: 0,
      },
    ],
  }
};

const seller = (id, name, weight) => {
  return {
    weight: weight,
    active: true,
    store: {
      id: id,
      name: name,
    },
    pid: '',
    price: {
      currency: 'USD',
      primary: 0,
      original: 0,
      savings: {
        amount: 0,
        percentage: 0,
      }
    },
    link: '',
  }
};

const initialState = (category, initialData) => {
  switch (category) {
    // Articles
    case 'products-articles':
      return {
        published: false,
        slug: '',
        title: '',
        description: '',
        note: '',
        rating: '',
        keywords: [],
        links: [{
          source: 'Amazon',
          url: '',
        }],
        image: {
          static: image(),
        },
        gallery: gallery(),
        content: '',
        partOf: {
          users: {
            authors: [],
          }
        },
        ...initialData,
      }

    case 'deals-articles':
      return {
        slug: '',
        title: '',
        description: '',
        image: {
          static: image(),
        },
        toc: false,
        content: '',
        partOf: {
          users: {
            authors: [],
          }
        },
        ...initialData,
      }

    case 'setups-articles':
      return {
        slug: '',
        title: '',
        description: '',
        keywords: [],
        image: {
          static: image(),
        },
        gallery: gallery(),
        products: [],
        content: '',
        partOf: {
          users: {
            authors: [],
          },
          setups: {
            collections: [],
          },
          products: {
            articles: [],
          },
        },
        ...initialData,
      }

    case 'blog-articles':
      return {
        slug: '',
        title: '',
        description: '',
        keywords: [],
        image: {
          static: image(),
        },
        toc: false,
        content: '',
        partOf: {
          users: {
            authors: [],
          },
          blog: {
            categories: [],
          }
        },
        ...initialData,
      }

    case 'news-articles':
      return {
        published: false,
        slug: '',
        title: '',
        description: '',
        author: '',
        link: '',
        image: {
          static: image(),
        },
        content: '',
        partOf: {
          news: {
            sources: [],
            topics: [],
            collections: [],
            categories: [],
          }
        },
        ...initialData,
      }

    default: 
      return {
        slug: '',
        title: '',
        description: '',
        image: {
          static: image(),
        },
        cover: cover(),
        content: '',
        partOf: {
          users: {
            authors: [],
          }
        },
        ...initialData,
      }
  }
}

export { initialState, seller };
