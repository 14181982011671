import React, { useState, useEffect } from 'react';

import { firestore } from 'utilities/firebase';
import {
  query,
  doc,
  collection,
  limit,
  orderBy,
  where,
  // startAfter,
  onSnapshot,
  arrayUnion,
  writeBatch
} from 'firebase/firestore'; 

import {
  DndContext,
  closestCorners,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  useFirestoreQuery,
  // useFirestoreInfiniteQuery,
} from '@react-query-firebase/firestore';

import ToDo from '../Components/ToDo';
import AddAltLink from '../Components/AddAltLink';
import ConfirmDialog from 'components/ConfirmDialog';
import CardContainer from '../Components/CardContainer';
import Stack from '@mui/material/Stack';

const List = ({ edit, draggable, section, subsection, taxonomySection, taxonomySubsection, taxonomyName, order, published, hits }) => {
  const [ data, setData ] = useState([]);

  const [ sourceId, setSourceId ] = useState();
  const [ sourceData, setSourceData ] = useState({});
  const [ sourceTarget, setSourceTarget ] = useState();
  const [ toDoData, setToDoData ] = useState(null);

  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const [ addToOpen, setAddToOpen ] = useState(false);
  const [ toDoOpen, setToDoOpen ] = useState(false);

  useEffect(() => {
    const conditions = [];

    if (taxonomySection) {
      conditions.push(where(`partOf.${taxonomySection}.${taxonomySubsection}`, 'array-contains', taxonomyName))
    }

    if (order)
      conditions.push(orderBy(order, 'desc'))

    if (published)
      conditions.push(where('published', '==', published === 'true'))

    if (hits)
      conditions.push(limit(hits))

    const q = query(
      collection(firestore, section, subsection, "content"),
      ...conditions,
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => setData([...querySnapshot.docs]));

    return () => unsubscribe();
  }, [section, subsection, taxonomySection, taxonomySubsection, taxonomyName, order, published]);

  const sourcesQuery = query(
    collection(firestore, "news", "sources", "content"),
  );

  const sources = useFirestoreQuery(["news", "sources"], sourcesQuery);

  /*
   * Use this when the lib gets fixed
  const articlesQuery = query(
    collection(firestore, "news", "articles", "content"),
    orderBy('publishedOn', 'desc'),
    limit(20),
  );

  const posts = useFirestoreInfiniteQuery(
    ["news", "articles", "all"],
    articlesQuery,
    (snapshot) => {
      const lastDocument = snapshot.docs[snapshot.docs.length - 1];
			return query(articlesQuery, startAfter(lastDocument));
    },
    {
      subscribe: true
    }, {
      refetchOnMount: "always"
    }
  );
  */

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 1000,
    },
  });

  const sensors = useSensors(
    mouseSensor,
    touchSensor,
  );

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const { publishedOn, title, link, source } = active.data.current;
      const sourceTitle = sources.data.docs.find((obj) => obj.id === source)

      setSourceData({
        date: publishedOn,
        title: title,
        url: link,
        source: sourceTitle.data().title,
      });

      setSourceId(active.id);
      setSourceTarget(over.id);

      setConfirmOpen(true);
    };
  };

  const handleAddTo = (article) => {
    const { publishedOn, title, link } = article.data();
    const source = sources.data.docs.find((obj) => obj.id === article.data().partOf.news.sources[0])

    setSourceId(article.id);

    setSourceData({
      date: publishedOn,
      title: title,
      url: link,
      source: source.data().title,
    });

    setAddToOpen(true);
  };

  const handleToDo = (article) => {
    setToDoData(article);
    setToDoOpen(true);
  };

  const addSource = (target) => {
    const batch = writeBatch(firestore);

    const targetRef = doc(firestore, 'news', 'articles', 'content', (target || sourceTarget))
    const sourceRef = doc(firestore, 'news', 'articles', 'content', sourceId)

    batch.update(targetRef, { links: arrayUnion(sourceData) });
    batch.delete(sourceRef);
    batch.commit();
  };

  if (!data.length) return <p>Loading</p>;

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
      >
        <Stack spacing={2}>
          {data.map((article, index) =>
            <CardContainer
              key={article.id}
              data={article}
              edit={edit}
              addTo={handleAddTo}
              todo={handleToDo}
              disabled={!draggable}
            />
          )}
        </Stack>
      </DndContext>

      <ConfirmDialog
        title="Add another source"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={addSource}
      >
        Add {sourceData.title}?
      </ConfirmDialog>
      
      <ToDo
        data={toDoData}
        open={toDoOpen}
        close={() => {
          setToDoOpen(false)
          setToDoData(null)
        }}
      />

      <AddAltLink
        data={sourceData}
        add={addSource}
        open={addToOpen}
        close={() => setAddToOpen(false)}
      />
    </>
  );
};

export default List;
