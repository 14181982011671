const image = () => {
  return {
    source: 'cloudinary',
    url: '',
    width: '',
    height: '',
    alt: '',
    author: '',
    author_url: '',
  };
};

const cover = () => {
  return {
    source: 'cloudinary',
    url: '',
    width: '',
    height: '',
    alt: '',
    author: '',
    author_url: '',
  };
};

const gallery = () => {
  return {
    source: 'cloudinary',
    alt: '',
    items: [
      {
        url: '',
        width: 0,
        height: 0,
      }, {
        url: '',
        width: 0,
        height: 0,
      }, {
        url: '',
        width: 0,
        height: 0,
      },
    ],
  }
};

const seller = (id, name, weight) => {
  return {
    weight: weight,
    active: true,
    store: {
      id: id,
      name: name,
    },
    pid: '',
    price: {
      currency: 'USD',
      primary: 0,
      original: 0,
      savings: {
        amount: 0,
        percentage: 0,
      }
    },
    link: '',
  }
};

const initialState = (category) => {
  switch (category) {
    // ToDo's
    case 'news-todos':
    case 'deals-todos':
    case 'products-todos':
    case 'setups-todos':
    case 'blog-todos':
      return {
        title: '',
        description: '',
        content: '',
        link: '',
        image: {
          static: image(),
        },
        partOf: {
          users: {
            authors: [],
          }
        }
      }

    // Articles
    case 'products-articles':
      return {
        published: false,
        slug: '',
        title: '',
        description: '',
        note: '',
        rating: '',
        keywords: [],
        links: [{
          source: 'Amazon',
          url: '',
        }],
        image: {
          static: image(),
        },
        gallery: gallery(),
        content: '',
        partOf: {
          users: {
            authors: [],
          }
        }
      }

    case 'deals-articles':
      return {
        slug: '',
        title: '',
        description: '',
        image: {
          static: image(),
        },
        toc: false,
        content: '',
        partOf: {
          users: {
            authors: [],
          }
        }
      }

    case 'deals-products': {
      const defaultSeller = {
        id: 'amazon',
        name: 'Amazon',
        weight: 1,
      };

      return {
        published: false,
        title: '',
        description: '',
        image: {
          static: image(),
        },
        seller: {
          ...seller(defaultSeller.id, defaultSeller.name, defaultSeller.weight),
        },
        sellers: {
          [defaultSeller.id]: {
            ...seller(defaultSeller.id, defaultSeller.name, defaultSeller.weight),
          },
        },
        partOf: {
          products: {
            brands: [],
            articles: [],
            collections: [],
            categories: [],
          },
          setups: {
            articles: [],
          },
        },
        content: '',
      }
    }

    case 'setups-articles':
      return {
        slug: '',
        title: '',
        description: '',
        keywords: [],
        image: {
          static: image(),
        },
        gallery: gallery(),
        products: [],
        content: '',
        partOf: {
          users: {
            authors: [],
          },
          setups: {
            collections: [],
          },
          products: {
            articles: [],
          },
        }
      }

    case 'blog-articles':
      return {
        slug: '',
        title: '',
        description: '',
        keywords: [],
        image: {
          static: image(),
        },
        toc: false,
        content: '',
        partOf: {
          users: {
            authors: [],
          },
          blog: {
            categories: [],
          }
        }
      }

    case 'news-articles':
      return {
        published: false,
        slug: '',
        title: '',
        description: '',
        author: '',
        link: '',
        image: {
          static: image(),
        },
        content: '',
        partOf: {
          news: {
            sources: [],
            topics: [],
            collections: [],
            categories: [],
          }
        }
      }

    case 'news-sources':
      return {
        slug: '',
        title: '',
        description: '',
        image: {
          static: image(),
        },
        cover: cover(),
        feed: {
          active: false,
          autopublish: false,
          type: 'rss',
          url: '',
        },
        content: '',
        partOf: {
          users: {
            authors: [],
          }
        }
      }

    // Collections
    case 'news-collections':
      return {
        slug: '',
        title: '',
        description: '',
        image: {
          static: image(),
        },
        cover: cover(),
        content: '',
        partOf: {
          users: {
            authors: [],
          }
        }
      }

    // Affiliates
    case 'affiliates-articles':
      return {
        published: false,
        title: '',
        description: '',
        link: '',
        image: {
          static: image(),
        },
        weight: 1,
        partOf: {},
      }

    case 'ads-articles':
      return {
        published: false,
        title: '',
        description: '',
        activePeriod: {
          from: new Date(),
          to: new Date(),
        },
        placement: 'feed',
        weight: 1,
        link: '',
        image: {
          static: image(),
        },
        partOf: {},
      }

    case 'section-index':
      return {
        slug: '',
        title: '',
        description: '',
        image: {
          static: image(),
        },
        cover: cover(),
      }

    default: 
      return {
        slug: '',
        title: '',
        description: '',
        image: {
          static: image(),
        },
        cover: cover(),
        content: '',
        partOf: {
          users: {
            authors: [],
          }
        }
      }
  }
}

export { initialState, seller };
