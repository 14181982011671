import React, { useContext } from 'react';
import { Outlet } from "react-router";
import { useRoutes, Navigate } from 'react-router-dom';

// Data
import { UserContext } from '../store/user';

// Views
import SignIn from './SignIn';
import Layout from './Layout';
import FileExplorer from 'views/FileExplorer';
import DashboardPage from './Dashboard/Page';

// News
import NewsArticles from './News/Articles';
import DealsProducts from './Deals/Products';

const routes = (isLoggedIn) => [
  { path: 'signin', element: isLoggedIn ? <Navigate to="/" /> : <SignIn /> },
  {
    element: isLoggedIn ? <Layout /> : <Navigate to="/signin" />,
    children: [
      { path: '/', element: <DashboardPage /> },

      // Sales
      { path: 'ads', element: <FileExplorer section="ads" subsection="articles" /> },
      { path: 'affiliates', element: <FileExplorer section="affiliates" subsection="articles" /> },

      // News
      { path: 'news/articles', element: <NewsArticles /> },
      { path: 'news/sources', element: <FileExplorer search={true} section="news" subsection="sources" /> },
      { path: 'news/topics', element: <FileExplorer search={true} section="news" subsection="topics" /> },
      { path: 'news/collections', element: <FileExplorer section="news" subsection="collections" /> },
      { path: 'news/categories', element: <FileExplorer section="news" subsection="categories" /> },
      { path: 'news/todos', element: <FileExplorer section="news" subsection="todos" order="publishedOn" hits={10} /> },

      // Setups
      { path: 'setups/articles', element: <FileExplorer search={true} section="setups" subsection="articles" order="publishedOn" hits={10} /> },
      { path: 'setups/collections', element: <FileExplorer section="setups" subsection="collections" /> },
      { path: 'setups/todos', element: <FileExplorer section="setups" subsection="todos" order="publishedOn" hits={10} /> },

      // Products
      { path: 'products/articles', element: <FileExplorer search={true} section="products" subsection="articles" order="publishedOn" hits={10} /> },
      { path: 'products/brands', element: <FileExplorer search={true} section="products" subsection="brands" order="publishedOn" hits={10} /> },
      { path: 'products/collections', element: <FileExplorer section="products" subsection="collections" /> },
      { path: 'products/categories', element: <FileExplorer section="products" subsection="categories" /> },
      { path: 'products/todos', element: <FileExplorer section="products" subsection="todos" order="publishedOn" hits={10} /> },

      // Blog
      { path: 'blog/articles', element: <FileExplorer search={true} section="blog" subsection="articles" order="publishedOn" hits={10} /> },
      { path: 'blog/categories', element: <FileExplorer section="blog" subsection="categories" /> },
      { path: 'blog/todos', element: <FileExplorer section="blog" subsection="todos" order="publishedOn" hits={10} /> },

      // Deals
      {
        path: 'deals',
        element: <Outlet />,
        children: [
          {
            path: 'products',
            element: <DealsProducts />,
            children: [
              {
                path: 'active',
                element: <FileExplorer
                  search={true}
                  section="deals"
                  subsection="products"
                  order="updatedOn"
                  published="true"
                  hits={10}
                  canGenerate={true}
                />
              },
              {
                path: 'best',
                element: <FileExplorer
                  section="deals"
                  subsection="products"
                  order="updatedOn"
                  published="true"
                  taxonomySection="deals"
                  taxonomySubsection="collections"
                  taxonomyName="g2ggkUs7OEKTkO4mni71"
                  hits={10}
                  canGenerate={true}
                />
              },
              {
                path: 'top',
                element: <FileExplorer
                  section="deals"
                  subsection="products"
                  order="updatedOn"
                  published="true"
                  taxonomySection="deals"
                  taxonomySubsection="collections"
                  taxonomyName="R9mc1NxCPAKZ5i4bOchs"
                  hits={10}
                  canGenerate={true}
                />
              },
              {
                path: 'expired',
                element: <FileExplorer
                  section="deals"
                  subsection="products"
                  order="updatedOn"
                  published="false"
                  hits={10}
                />
              },
            ]
          },
          { path: 'articles', element: <FileExplorer search={true} section="deals" subsection="articles" order="publishedOn" hits={10} /> },
          { path: 'collections', element: <FileExplorer section="deals" subsection="collections" /> },
          { path: 'stores', element: <FileExplorer section="deals" subsection="stores" /> },
          { path: 'todos', element: <FileExplorer section="deals" subsection="todos" order="publishedOn" hits={10} /> },
        ]
      },
      { path: 'pages', element: <FileExplorer section="pages" subsection="articles" /> },
      { path: 'team', element: <FileExplorer section="team" subsection="articles" /> },
    ],
  },
];

const Routes = () => {
  const { userData } = useContext(UserContext);
  const routesMap = useRoutes(routes(userData));

  return routesMap;
}

export default Routes;
