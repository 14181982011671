const PromptGenerator = (section, data) => {
  if (section === 'deals') {
    const prompt = `${data.title} is discounted from ${data.price.original} to ${data.price.primary} (-${data.price.discount}% off) on Amazon. Use this url to link the product to the Amazon product page: ${data.url}`;
    
    const parameters = {
      type: 'object',
      properties: {
        title: {
          type: 'string',
          description: 'Title of the article',
        },
        slug: {
          type: 'string',
          description: 'URL slug of the article',
        },
        keywords: {
          type: 'array',
          description: 'Keywords of the article',
          items: {
            type: 'string',
          },
        },
        description: {
          type: 'string',
          description: 'Description of the article',
        },
        content: {
          type: 'string',
          description: 'Body content of the article in the markdown format without HTML',
        },
      },
    };

    return {
      // model: 'gpt-3.5-turbo',
      prompt,
      schema: {
        name: 'deal_article',
        description: "Write an article in at least 750 words with a catchy title and no images about the product and its discount on Amazon from the input of the prompt.",
        parameters,
      }
    }
  };

  return null;
};

export default PromptGenerator;
