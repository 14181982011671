import React, { useContext } from 'react';
// import Firebase from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { HeaderContext } from 'store/header';

import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

const Header = ({ children }) => {
  const { title } = useContext(HeaderContext);
 	const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const auth = getAuth();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Toolbar>
      {children}

      <Typography
        variant="h6"
        component="h1"
        sx={{ flexGrow: 1 }}
      >
        {title}
      </Typography>

      <IconButton onClick={handleClick}>
        <AccountCircleIcon />
      </IconButton>

     	<Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => auth.signOut()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>
      </Menu> 
    </Toolbar>
  );
};

export default Header;
