import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SET_DATA } from '../Constants';

const arePropsEqual = (prev, next) =>
  prev.data.published === next.data.published;

const Published = React.memo(({ data, dispatch }) => {
  const [ canPublish, setCanPublish ] = useState(false);

  useEffect(() => {
    if (data.published !== undefined) {
      setCanPublish(data.published);
    }
  }, [data.published]);

  const handleChange = (e) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: 'published',
        value: e.target.checked,
      },
    });

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            name="published"
            label="Published"
            checked={canPublish}
            onChange={handleChange}
          />
        }
        label="Published"
      />
    </FormGroup>
  );
}, arePropsEqual);

export default Published;
