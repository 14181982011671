import React from 'react';
import TextField from '@mui/material/TextField';
import { SET_DATA } from '../Constants';

const arePropsEqual = (prev, next) => prev.data.rating === next.data.rating;

const Rating = React.memo(({ data, dispatch }) => {

  const handleChange = (e) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: e.target.name,
        value: e.target.value,
      },
    });

  return (
    <TextField
      name="rating"
      label="Rating"
      variant="standard"
      value={data.rating}
      onChange={handleChange}
      required
    />
  );
}, arePropsEqual);

export default Rating;
