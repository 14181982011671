import React from 'react';
import TextField from '@mui/material/TextField';
import { SET_DATA } from '../Constants';

const arePropsEqual = (prev, next) => prev.data.author === next.data.author;

const Author = React.memo(({ data, dispatch }) => {
  const handleChange = (e) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: e.target.name,
        value: {
          title: e.target.value,
        },
      },
    });

  return (
    <TextField
      name="author"
      label="Author"
      variant="standard"
      value={data.author.title}
      onChange={handleChange}
    />
  );
}, arePropsEqual);

export default Author;
