import React, { useState, useEffect } from 'react';

import { firestore } from 'utilities/firebase';
import { doc, getDoc } from 'firebase/firestore'; 
import algoliasearch from 'algoliasearch/lite';

import ContentForm from 'forms/Content';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import CircularProgress from '@mui/material/CircularProgress';

const filter = createFilterOptions();

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY,
);

const Taxonomy = ({ section, subsection, ids, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState([]);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const index = searchClient.initIndex(`${section}_${subsection}`);

  useEffect(async () => {
    if (ids.length) {
      const selecteData = await ids.reduce(async (acc, id) => {
        const result = await acc;

        const docRef = doc(firestore, section, subsection, "content", id);
        const docSnap = await getDoc(docRef);

        return [
          ...result,
          {
            id: id,
            title: docSnap.data().title,
          },
        ];
      }, Promise.resolve([]));

      setSelected(selecteData);
    };
  }, [section, subsection, ids.length]);

  useEffect(async () => {
    const { hits } = await index.search(inputValue, { hitsPerPage: 10 });

    setOptions(hits);
  }, [inputValue]);

  const handleCreate = (obj) => {
    const justIds = selected.reduce((acc, cur) => {
      acc.push(cur.id);

      return acc;
    }, []);

    onChange(section, subsection, [...justIds, obj.id]);

    setSelected((prevValue) => [
      ...prevValue,
      {
        id: obj.id,
        title: obj.title,
      },
    ]);

    setOpen(false);
  };

  return (
    <>
      <Autocomplete
        id={`${section}-${subsection}`}
        multiple
        freeSolo
        filterSelectedOptions
        value={inputValue}
        onChange={(event, values) => {
          const value = values[values.length - 1];

          if (value && value.inputValue) {
            setOpen(true);
          } else {
            const ids = values.reduce((acc, sel) => {
              acc.push(sel.objectID
                ? {
                  id: sel.objectID,
                  title: sel.title,
                }
                : sel
              );

              return acc;
            }, [])

            const justIds = ids.reduce((acc, cur) => {
              acc.push(cur.id);

              return acc;
            }, []);

            onChange(section, subsection, justIds);
            setSelected(ids);
          };
        }}

        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}

        options={options}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
				filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.title);

          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}

        getOptionLabel={(option) => {
            // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}

        value={selected}
        noOptionsText="No options"
        renderInput={(params) => <TextField {...params} label={subsection} variant="standard" />}
      />

      <ContentForm
        open={open}
        close={() => setOpen(false)}
        section={section}
        subsection={subsection}
        save={handleCreate}
      />
    </>
  );
};

export default Taxonomy;
