import React, { useReducer, useState, useEffect } from 'react';

import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";
import { auth, firestore } from 'utilities/firebase';
import { doc, collection, setDoc, addDoc } from 'firebase/firestore'; 

import { initialState } from './Model';
import Context from '../Context';
import Reducer from './Reducer';
import Form from './Form';

import Modal from 'components/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import PromptGenerator from './PromptGenerator';

import { SET_STATE } from '../Constants';

function insertDealElement(inputText, dealElement) {
    let lines = inputText.split('\n');
    let firstParagraphEnd = -1;
    let lastParagraphStart = -1;

    // Find the end of the first paragraph
    for (let i = 0; i < lines.length; i++) {
        if (lines[i] === '' && i > 0 && lines[i - 1] !== '') {
            firstParagraphEnd = i;
            break;
        }
    }

    // Find the start of the last paragraph
    for (let i = lines.length - 1; i >= 0; i--) {
        if (lines[i] === '' && i < lines.length - 1 && lines[i + 1] !== '') {
            lastParagraphStart = i;
            break;
        }
    }

    if (firstParagraphEnd !== -1) {
        lines.splice(firstParagraphEnd + 1, 0, dealElement, '');
    }

    if (lastParagraphStart !== -1 && lastParagraphStart !== firstParagraphEnd) {
        lines.splice(lastParagraphStart + 1, 0, dealElement, '');
    }

    return lines.join('\n');
}

function formatMarkdownText(inputText) {
    // Split the text into lines
    let lines = inputText.split('\n');

    // Process each line
    for (let i = 0; i < lines.length; i++) {
        // Check for markdown headers and ensure they are followed by a single line break
        if (lines[i].startsWith('#')) {
            // Add an extra line break after headers if not already present
            if (i + 1 < lines.length && lines[i + 1] !== '') {
                lines.splice(i + 1, 0, '');
            }
        } else {
            // Replace double line breaks with single line breaks in other cases
            if (lines[i] === '' && i + 1 < lines.length && lines[i + 1] === '') {
                lines.splice(i, 1);
            }
        }
    }

    // Join the lines back together
    return lines.join('\n');
}

const getOpenAIResponse = httpsCallable(functions, 'getOpenAIResponse');

const GenerateForm = ({ open, close, section, subsection, data }) => {
  const [ loading, setLoading ] = useState(true);
  const [ state, dispatch ] = useReducer(Reducer, initialState(`${section}-${subsection}`));

  const create = async (createdData) => {
    createdData.createdOn = new Date();

    if (!createdData.publishedOn) {
      createdData.publishedOn = new Date();
    }

    if (section !== 'news')
      createdData.partOf.users.authors.push(auth.currentUser.uid);

    const colRef = collection(firestore, section, subsection, 'content');
    const newDoc = await addDoc(colRef, createdData);

    return {
      id: newDoc.id,
      ...createdData,
    }
  };

  const handleSave = async () => {
    const savedObj = await create(state);

    close(savedObj);
  };

  useEffect(async () => {
    if (open) {
      if (data) {
        const prompt = PromptGenerator(section, data);
        const rawArticleObj = await getOpenAIResponse({ prompt: prompt.prompt, schema: prompt.schema });
        const articleObj = JSON.parse(rawArticleObj.data.reply.function_call.arguments);

        if (articleObj.content) {
          const formattedMarkdown = formatMarkdownText(articleObj.content);
          const dealElement = `<Deal title="${data.title}" id="${data.id}" />`;
          const finalMarkdown = insertDealElement(formattedMarkdown, dealElement);

          articleObj.content = finalMarkdown;
        };

        dispatch({
          type: SET_STATE,
          value: initialState(`${section}-${subsection}`, articleObj),
        });
      
        setLoading(false);
      };

    } else {
      setLoading(true);
    };
  }, [open]);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <Modal
        isOpen={open}
        handleClose={close}
        title="Editor"
        fullScreen
      >
        {loading
          ? <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          : <Form
              edit={data ? true : false}
              type={`${section}-${subsection}`}
              save={handleSave}
              close={close}
            />
        }
      </Modal>
    </Context.Provider>
  );
};

export default GenerateForm;
