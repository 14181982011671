import React, { useState } from 'react';
import { doc, getDoc } from 'firebase/firestore'; 
import { firestore } from 'utilities/firebase';
import Button from '@mui/material/Button';

// Components
import ContentForm from 'forms/Content';

const EditIndexPage = () => {
  const [ formOpen, setFormOpen ] = useState(false);
  const [ formData, setFormData ] = useState();

  const handleEditIndex = async () => {
    const indexRef = doc(firestore, 'news', 'articles');
    const index = await getDoc(indexRef);

    setFormData(index);
    setFormOpen(true);
  };

  return (
    <>
      <Button onClick={handleEditIndex}>
        Edit page
      </Button>

      <ContentForm
        open={formOpen}
        close={() => setFormOpen(false)}
        section="section"
        subsection="index"
        data={formData}
        save={() => setFormOpen(false)}
      />
    </>
  );
};

export default EditIndexPage;
