import React from 'react';
import {
    fromUnixTime, differenceInDays, format, formatDistance,
} from 'date-fns';

// Components
import Taxonomy from 'components/Taxonomy';

// Actions
import Collection from './Actions/Collection';

// UI
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { grey, blue } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const formatTimeOfUpdate = (timestamp) => {
  const now = new Date();
  const fullDate = fromUnixTime(timestamp);

  return differenceInDays(now, fullDate) < 7
    ? formatDistance(fullDate, now, { addSuffix: true })
    : format(fullDate, 'MM/dd/yyyy');
};

const Taxonomies = (article) => {
  if (article.partOf) {
    const list = [];
    const { partOf } = article;

    Object.keys(partOf).map((section, sectionIndex) => {
      if (Object.keys(partOf)[sectionIndex] !== 'users') {
        Object.keys(partOf[section]).map((subsection) => {
          if (partOf[section][subsection].length) {
            if (section !== 'products' && subsection !== 'articles')
              partOf[section][subsection].map((tax) =>
                list.push({
                  section,
                  subsection,
                  id: tax,
                })
              )
          }
        });
      };
    });

    return list;
  };

  return [];
};

const getImageUrl = (image) => {
 	switch (image.source) {
    case 'cloudinary':
      return `https://res.cloudinary.com/dev-and-gear/image/upload/w_200,q_auto,f_auto/${image.url}`;
    case 'unsplash':
      return `https://source.unsplash.com/${image.url}/200`; 
    case 'external':
      return image.url;
    case 'twitter':
      return `https://res.cloudinary.com/dev-and-gear/image/twitter_name/w_400,q_auto,f_auto/${image.url}`;
    default:
      return image.url;
  } 
};

const Card = ({ section, subsection, article, generate, edit, remove }) => {
  const { ref, id } = article;
  const data = article.data();

  const handleGenerate = () => generate({
    id,
    title: data.title,
    price: {
      primary: data.seller.price.primary,
      original: data.seller.price.original,
      discount: data.seller.price.savings.percentage,
    },
    url: data.sellers.amazon.link,
  });
  const handleEdit = () => edit(article);
  const handleRemove = () => remove(ref.path);

  return (
    <Stack component={Paper} direction="column">
      <Stack direction="row" alignItems="center" justifyContent="end">
        {section === 'deals' &&
          subsection === 'products' &&
          <>
            <Collection section="deals" subsection="collections" name="best" data={article} />
            <Collection section="deals" subsection="collections" name="top" data={article} />
          </>
        }

        {generate &&
          <IconButton onClick={handleGenerate}>
            <SmartToyIcon fontSize="small" />
          </IconButton>
        }

        <IconButton onClick={handleEdit}>
          <EditIcon fontSize="small" />
        </IconButton>

        <IconButton onClick={handleRemove}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>

      <Divider />

      <Box sx={{ padding: 1 }}>
        <Stack direction="row" spacing={1}>
          {data.image && data.image.static &&
            <Avatar
              src={getImageUrl(data.image.static)}
              variant="square"
              sx={{
                width: 82,
                height: 82,
              }}
            /> 
          }

          <Stack spacing={1}>
            <Stack direction="column" spacing={0}>
              {data.publishedOn
                ? <Typography variant="caption">
                    Published: {formatTimeOfUpdate(data.publishedOn.seconds)}
                  </Typography>
                : <div></div>
              }

              {data.publishedOn && data.updatedOn &&
                data.updatedOn.seconds > data.publishedOn.seconds &&
                  <Typography variant="caption">
                    Updated: {formatTimeOfUpdate(data.updatedOn.seconds)}
                  </Typography>
              }
            </Stack>

            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                color: grey[800],
              }}
            >
              {data.link
                ? <Link
                    href={data.link}
                    target="_blank"
                    rel="noopener"
                    sx={{ fontWeight: 'bold', color: blue[800] }}
                    underline="none"
                  >
                    {data.title}
                  </Link>
                : data.title
              }
            </Typography>

            {data.seller && 
              <Stack direction="row" alignItems="flex-end" spacing={1}>
                <Typography variant="body2">
                  ${data.seller.price.primary}
                </Typography>

                <Typography variant="caption" sx={{ textDecoration: 'line-through' }}>
                  ${data.seller.price.original}
                </Typography>

                <Typography variant="caption">
                  (-{data.seller.price.savings.percentage}%)
                </Typography>
              </Stack>
            }

            <Stack direction="row" spacing={1}>
              {Taxonomies(data).map((tax) => 
                <Taxonomy
                  key={tax.id}
                  section={tax.section}
                  subsection={tax.subsection}
                  id={tax.id}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Card;
