const SET_STATE = 'SET_STATE';

const SET_DATA = 'SET_DATA';
const DELETE_DATA = 'DELETE_DATA';

const ADD_TAG = 'ADD_TAG';
const DELETE_TAG = 'DELETE_TAG';

const ADD_KEYWORD = 'ADD_KEYWORD';
const DELETE_KEYWORD = 'DELETE_KEYWORD';

const ADD_LINK = 'ADD_LINK';
const ADD_LINK_FIELD = 'ADD_LINK_FIELD';
const DELETE_LINK_FIELD = 'DELETE_LINK_FIELD';
const EDIT_LINK_FIELD = 'EDIT_LINK_FIELD';
const DELETE_LINK = 'DELETE_LINK';

const ADD_IMAGE = 'ADD_IMAGE';
const EDIT_IMAGE = 'EDIT_IMAGE';
const DELETE_IMAGE = 'DELETE_IMAGE';

const SET_COVER = 'SET_COVER';

const SET_GALLERY = 'SET_GALLERY';
const ADD_GALLERYIMAGE = 'ADD_GALLERYIMAGE';
const EDIT_GALLERYIMAGE = 'EDIT_GALLERYIMAGE';
const DELETE_GALLERYIMAGE = 'DELETE_GALLERYIMAGE';
const MOVE_GALLERYIMAGE = 'MOVE_GALLERYIMAGE';

const ADD_TAXONOMY = 'ADD_TAXONOMY';
const DELETE_TAXONOMY = 'DELETE_TAXONOMY';
const ADD_TAXONOMY_ITEM = 'ADD_TAXONOMY_ITEM';
const MOVE_TAXONOMY_ITEM = 'MOVE_TAXONOMY_ITEM';
const DELETE_TAXONOMY_ITEM = 'DELETE_TAXONOMY_ITEM';

const ADD_SELLER = 'ADD_SELLER';
const REMOVE_SELLER = 'REMOVE_SELLER';
const EDIT_SELLER = 'EDIT_SELLER';
const SET_ACTIVE = 'SET_ACTIVE';
const SET_SELLER_PRICE = 'SET_SELLER_PRICE';
const SET_PRIMARY_SELLER = 'SET_PRIMARY_SELLER';
const MOVE_SELLER = 'MOVE_SELLER';

export {
  SET_STATE,
  SET_DATA,
  DELETE_DATA,

  ADD_TAG,
  DELETE_TAG,

  ADD_KEYWORD,
  DELETE_KEYWORD,

  ADD_LINK,
  ADD_LINK_FIELD,
  DELETE_LINK_FIELD,
  EDIT_LINK_FIELD,
  DELETE_LINK,

  ADD_IMAGE,
  EDIT_IMAGE,
  DELETE_IMAGE,

  SET_COVER,

  SET_GALLERY,
  ADD_GALLERYIMAGE,
  EDIT_GALLERYIMAGE,
  DELETE_GALLERYIMAGE,
  MOVE_GALLERYIMAGE,

  ADD_TAXONOMY,
  DELETE_TAXONOMY,
  ADD_TAXONOMY_ITEM,
  MOVE_TAXONOMY_ITEM,
  DELETE_TAXONOMY_ITEM,

  ADD_SELLER,
  REMOVE_SELLER,
  EDIT_SELLER,
  SET_ACTIVE,
  SET_SELLER_PRICE,
  SET_PRIMARY_SELLER,
  MOVE_SELLER,
};
