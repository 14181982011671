import React, { useReducer, useState, useEffect } from 'react';

import { auth, firestore } from 'utilities/firebase';
import { doc, collection, setDoc, addDoc } from 'firebase/firestore'; 

import { initialState } from './Model';
import Context from './Context';
import Reducer from './Reducer';
import Form from './Form';

import Modal from 'components/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { SET_STATE } from './Constants';

const ContentForm = ({ open, close, section, subsection, data, save }) => {
  const [ loading, setLoading ] = useState(true);
  const [ state, dispatch ] = useReducer(Reducer, initialState(`${section}-${subsection}`));

  const create = async (createdData) => {
    createdData.createdOn = new Date();

    if (!createdData.publishedOn) {
      createdData.publishedOn = new Date();
    }

    if (section !== 'news')
      createdData.partOf.users.authors.push(auth.currentUser.uid);

    const colRef = collection(firestore, section, subsection, 'content');
    const newDoc = await addDoc(colRef, createdData);

    return {
      id: newDoc.id,
      ...createdData,
    }
  };

  const update = async (updatedData) => {
    updatedData.updatedOn = new Date();

    const docRef = doc(firestore, data.ref.path);

    await setDoc(docRef, updatedData);

    return updatedData;
  };

  const handleSave = async () => {
    const obj = data?.ref
      ? await update(state)
      : await create(state);

    save(obj);
  };

  useEffect(async () => {
    if (open) {
      if (data?.ref) {
        dispatch({
          type: SET_STATE,
          value: {
            ...data.data(),
          }
        });
      } else {
        dispatch({
          type: SET_STATE,
          value: initialState(`${section}-${subsection}`),
        });
      };

      setLoading(false);
    } else {
      setLoading(true);
    };
  }, [open]);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <Modal
        isOpen={open}
        handleClose={close}
        title="Editor"
        fullScreen
      >
        {loading
          ? <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          : <Form
              edit={data ? true : false}
              type={`${section}-${subsection}`}
              save={handleSave}
              close={close}
            />
        }
      </Modal>
    </Context.Provider>
  );
};

export default ContentForm;
