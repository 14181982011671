import React from 'react';

import { functions } from 'utilities/firebase';
import { httpsCallable } from "firebase/functions";

const UpdatePrices = () => {
  const handleClick = () => httpsCallable(functions, 'manualDealsObserver')();

  return (
    <div onClick={handleClick}>
      Update Prices
    </div>
  );
};

export default UpdatePrices;
