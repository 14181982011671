import React, { useState } from 'react';
import { Outlet } from "react-router";
import { Link } from 'react-router-dom';

// Components
import Scraper from '../Components/Scraper';
import Actions from '../Components/Actions';

// UI
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      sx={{ flex: 1 }}
      {...props}
    />
  );
};

const DealsProducts = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Container
      maxWidth="none"
      disableGutters
    >
      <Toolbar>
        <Scraper />
        <Actions />
      </Toolbar>

      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{ display: 'flex', borderBottom: '1px solid #eee' }}
        >
          <LinkTab label="Active" to="active" />
          <LinkTab label="Best" to="best" />
          <LinkTab label="Top" to="top" />
          <LinkTab label="Expired" to="expired" />
        </Tabs>
      </Box>

      <Outlet />
    </Container>
  );
}

export default DealsProducts;
