import React, { useState } from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Components
import Price from './Price';

// UI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {Typography} from '@mui/material';

// Actions
import { EDIT_SELLER, REMOVE_SELLER, SET_ACTIVE } from '../../Constants';

const Seller = ({ id, data, primary, setPrimary, dispatch, editing }) => {
  const [ expanded, setExpanded ] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };


  const handleDelete = () => {
    dispatch({
      type: REMOVE_SELLER,
      value: id,
    });
  }

  const handleEdit = (e) =>
    dispatch({
      type: EDIT_SELLER,
      value: {
        id: id,
        field: e.target.name,
        value: e.target.value,
      },
    });

  const handleActive = (e) =>
    dispatch({
      type: SET_ACTIVE,
      value: {
        id: id,
        field: "active",
        value: e.target.checked,
      },
    });

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      ref={setNodeRef}
      style={style}
    >
     	{editing &&
        <IconButton {...attributes} {...listeners}>
          <DragIndicatorIcon />
        </IconButton>
      } 

			<Accordion
        component={Paper}
        elevation={expanded && !editing ? 3 : 1}
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded && !editing}
        sx={{ width: '100%' }}
      >
				<AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',
            }
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <IconButton
              size="small"
              onClick={!primary ? setPrimary : null}
            >
              {primary ? <StarIcon /> : <StarOutlineIcon />}
            </IconButton>

            <Typography variant="body1">
              {data.store.name}
            </Typography>

            <FiberManualRecordIcon
              fontSize="small"
              sx={{ color: data.active ? 'green' : 'gray' }}
            />
            
          </Stack>

          {!editing &&
            <IconButton onClick={() => setExpanded(!expanded)}>
              {(expanded && !editing) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Switch
                    name="active"
                    label="Active"
                    checked={data.active}
                    onChange={handleActive}
                    size="small"
                  />
                }
                label="Active"
              />
            </Stack>

            <TextField
              name="pid"
              label="Product ID"
              variant="standard"
              value={data.pid}
              onChange={handleEdit}
              fullWidth
              multiline
              required
            /> 

            <TextField
              name="link"
              label="Link"
              variant="standard"
              value={data.link}
              onChange={handleEdit}
              fullWidth
              multiline
              required
            /> 

            <Price
              id={id}
              data={data.price}
              dispatch={dispatch}
            />
          </Stack>
        </AccordionDetails>
			</Accordion>

     	{editing &&
        <IconButton
          onClick={handleDelete}
          disabled={primary}
        >
          <DeleteIcon />
        </IconButton>
      } 
    </Stack>
  );
};

export default Seller;
