import React from 'react';

import { firestore } from 'utilities/firebase';
import { doc } from 'firebase/firestore'; 
import { useFirestoreDocument } from "@react-query-firebase/firestore";

import Chip from '@mui/material/Chip';

const Taxonomy = React.memo(({ section, subsection, id }) => {
  const docRef = doc(firestore, section, subsection, "content", id);
  const { isLoading, data } = useFirestoreDocument([id], docRef);

  return (
    <Chip
      label={isLoading
        ? 'Loading'
        : data.data().title
      }
      color="primary"
      size="small"
    />
  );
});

export default Taxonomy;
