import React, { useState, useEffect } from 'react';

import { firestore } from 'utilities/firebase';
import {
  collection,
  query,
  onSnapshot,
} from 'firebase/firestore'; 

// Forms
import StoreForm from 'forms/Store';

// UI
import Modal from 'components/Modal';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// Actions
import { ADD_SELLER } from '../../Constants';

const filter = createFilterOptions();

const AddSeller = ({ sellers, dispatch }) => {
	const [ stores,			setStores			] = useState([]);
	const [ inputValue,	setInputValue	] = useState('');
	const [ list,				setList				] = useState([]);
	const [ seller,			setSeller			] = useState();
	const [ formOpen,		setFormOpen		] = useState(false);
	const [ formData,		setFormData		] = useState({
		id: '',
		name: '',
	});
	
	useEffect(() => {
    const q = query(collection(firestore, 'deals', 'stores', 'content'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => setStores(querySnapshot.docs));

    return () => unsubscribe();
	}, []);

	// Observe sellers
	useEffect(async () => {
		if (stores.length) {
			const presentSellers = Object.keys(sellers);

			const data = stores.reduce((acc, store) => {
				if (!presentSellers.includes(store.data().id))
					acc.push({
						id: store.data().id,
						name: store.data().title,
						weight: Object.keys(sellers).length + 1,
					});

				return acc;
			}, []);

			setList(data);
		};
	}, [stores.length, Object.keys(sellers).length]);

	// Store creation
	const handleCreate = (data) => {
		dispatch({
			type: ADD_SELLER,
			value: {
				id: data.id,
				name: data.name,
			},
		});

		handleToggleForm();
	};

	// Submit
	const handleAdd = () => {
		dispatch({
			type: ADD_SELLER,
			value: seller,
		});
		
		setInputValue('');
		setSeller();
	};

	const handleToggleForm = () => setFormOpen(!formOpen);

  return (
		<>
			<Stack direction="row" spacing={2}>
				<Autocomplete
					disablePortal
					freeSolo

					options={list}
					filterOptions={(options, params) => {
						const filtered = filter(options, params);

						if (params.inputValue !== '')
							filtered.push({
								id: params.inputValue,
								name: `Create "${params.inputValue}" store`,
								inputValue: params.inputValue,
							});

						return filtered;
					}}
					getOptionLabel={(option) => option.name}

					value={seller ? seller : null}
					onChange={(e, value) => {
						if (value && value.inputValue) {
							setInputValue('');

							setFormData({
								id: '',
								name: value.inputValue,
							});

							handleToggleForm();
						} else {
							setSeller(value);
						};
					}}

					inputValue={inputValue}
					onInputChange={(e, value) => setInputValue(value)}
					renderInput={(params) =>
						<TextField
							{...params}
							label="Add seller"
							variant="standard"
							fullWidth
						/>
					}
					fullWidth
				/>

				<IconButton
					onClick={handleAdd}
					disabled={seller && seller.id ? false : true}
				>
					<AddCircleIcon />
				</IconButton>
			</Stack>

      <Modal
        isOpen={formOpen}
        handleClose={handleToggleForm}
        title="Add store"
      >
				<StoreForm
					data={formData}
					submit={handleCreate}
					close={handleToggleForm}
				/>
			</Modal>
		</>
  );
};

export default AddSeller;
