import React from 'react';
import TextField from '@mui/material/TextField';
import { SET_DATA } from '../Constants';

const arePropsEqual = (prev, next) => prev.data.placement === next.data.placement;

const Placement = React.memo(({ data, dispatch }) => {
  const handleChange = (e) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: e.target.name,
        value: e.target.value,
      },
    });

  return (
    <TextField
      name="placement"
      label="Placement"
      variant="standard"
      value={data.placement}
      onChange={handleChange}
      fullWidth
    /> 
  );
}, arePropsEqual);

export default Placement;
