import React, { useState } from 'react';
import MDEditor, {
  commands, link, bold, italic, strikethrough, fullscreen,
} from '@uiw/react-md-editor';

import CloudinaryLibrary from 'components/CloudinaryLibrary';
import Search from 'components/Search';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ImageIcon from '@mui/icons-material/Image';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddIcon from '@mui/icons-material/Add';

import { SET_DATA } from '../Constants';

const insertAtLineStart = (
  val,
  cursorIdx,
  input,
) => {
  const content = input.value;
  let startIdx = 0;

  while (cursorIdx--) {
    let char = content[cursorIdx];
    if (char === '\n') {
      startIdx = cursorIdx + 1;
      break;
    }
  }

  input.focus();
  input.setRangeText(val, startIdx, startIdx);
  input.dispatchEvent(new Event('input', { bubbles: true }));
};

let imageSelected = null;
let dealSelected = null;
let productSelected = null;

const image = {
  name: 'image',
  groupName: 'image',
  keyCommand: 'image',
  buttonProps: { 'aria-label': 'Insert image' },
  icon: <ImageIcon fontSize="small" />,
  children: ({ close, execute }) => {
    const handleInsert = (img) => {
      imageSelected = img.assets[0];
    };

    return (
      <div style={{ width: 250 }}>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ padding: 2 }}>
          <CloudinaryLibrary onInsert={handleInsert}>
            <IconButton
              sx={{
                backgroundColor: '#000',
                color: '#fff',
              }}
              size="small"
            >
              <ImageIcon fontSize="small" />
            </IconButton>
          </CloudinaryLibrary>

          <IconButton size="small" onClick={() => {
            execute();
            close();
          }}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Stack>
      </div>
    );
  },
  execute: (state, api) => {
    if (imageSelected) {
      insertAtLineStart(
      `<Image source="cloudinary"
             url="/v${imageSelected.version}/${imageSelected.public_id}"
             width="${imageSelected.width}"
             height="${imageSelected.height}"
             alt=""
             caption=""
             credit=""
             crediturl="" />`,
        state.selection.start, api.textArea
      );

      imageSelected = null;
    };
  },
};

const deal = {
  name: 'deal',
  groupName: 'deal',
  keyCommand: 'deal',
  buttonProps: { 'aria-label': 'Insert deal' },
  icon: <ShoppingCartIcon fontSize="small" />,
  children: ({ close, execute }) => {
    const handleSelect = (hit) => {
      dealSelected = hit;
    };

    return (
      <div style={{ width: 250 }}>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ padding: 2 }}>
          <Search section="deals" subsection="products" select={handleSelect} />

          <IconButton
            size="small"
            onClick={() => {
              execute();
              close();
            }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Stack>
      </div>
    );
  },
  execute: (state, api) => {
    if (dealSelected) {
      insertAtLineStart(`<Deal title="${dealSelected.title}" id="${dealSelected.objectID}" />`, state.selection.start, api.textArea);

      dealSelected = null;
    };
  },
};

const product = {
  name: 'product',
  groupName: 'product',
  keyCommand: 'product',
  buttonProps: { 'aria-label': 'Insert productt' },
  icon: <HeadphonesIcon fontSize="small" />,
  children: ({ close, execute }) => {
    const handleSelect = (hit) => {
      productSelected = hit;
    };

    return (
      <div style={{ width: 250 }}>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ padding: 2 }}>
          <Search section="products" subsection="articles" select={handleSelect} />

          <IconButton
            size="small"
            onClick={() => {
              execute();
              close();
            }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Stack>
      </div>
    );
  },
  execute: (state, api) => {
    if (productSelected) {
      insertAtLineStart(`<Product title="${productSelected.title}" id="${productSelected.objectID}" />`, state.selection.start, api.textArea);

      productSelected = null;
    };
  },
};

const arePropsEqual = (prev, next) =>
  prev.data.content === next.data.content;

const Content = React.memo(({ data, dispatch }) => {

  const handleChange = (value) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: 'content',
        value: value,
      },
    });

  return (
    <div data-color-mode="light">
      <MDEditor
        value={data.content ? data.content : ''}
        onChange={handleChange}
        commands={[
          link,
          bold,
          italic,
          strikethrough,
          fullscreen,
          commands.group([commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6], {
            name: 'title',
            groupName: 'title',
            buttonProps: { 'aria-label': 'Insert title'}
          }),
          commands.group([], image),
          commands.group([], deal),
          commands.group([], product),
        ]}
        preview="edit"
        height={300}
      />
    </div>
  );
}, arePropsEqual);

export default Content;
