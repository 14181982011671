import React, { useState, useEffect } from 'react';
import { firestore } from 'utilities/firebase';
import { query, collection, where, doc, setDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useFirestoreQuery } from "@react-query-firebase/firestore";

import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import CircularProgress from '@mui/material/CircularProgress';
import { red, grey, orange } from '@mui/material/colors';

const IconsMap = {
  featured: LocalFireDepartmentIcon,
  top: StarIcon,
};

const TopStatus = ({ name, data }) => {
  const [ collectionId, setCollectionId ] = useState('');
  const ActiveIcon = IconsMap[name]
  const ActiveColor = name === 'top' ? orange : red;

  // Get collection ID
  const refQuery = query(
    collection(firestore, "news", "collections", "content"),
    where('slug', '==', name)
  );

  const newsCollection = useFirestoreQuery(
    ["news", "collections", name],
    refQuery
  );

  useEffect(() => {
    if (!newsCollection.isLoading)
      setCollectionId(newsCollection.data.docs[0].id);
  }, [newsCollection.isLoading]);

  // Add/Remove
  const docRef = doc(firestore, data.ref.path);

  const add = () =>
    setDoc(docRef, {
      partOf: {
        news: {
          collections: arrayUnion(collectionId)
        }
      }
    }, { merge: true });

  const remove = () =>
    setDoc(docRef, {
      partOf: {
        news: {
          collections: arrayRemove(collectionId)
        }
      }
    }, { merge: true });

  if (newsCollection.isLoading)
    return <CircularProgress size={12} />

  return ( 
    <IconButton
      onClick={() => data.data().partOf.news.collections.includes(collectionId) ? remove() : add()}
    >
      <ActiveIcon
        fontSize="small"
        sx={{
          color: data.data().partOf.news.collections.includes(collectionId) ? ActiveColor[500] : grey[500]
        }}
      />
    </IconButton>
  );
};

export default TopStatus;
