import React from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { SET_SELLER_PRICE } from '../../Constants';

const Price = ({ id, data, dispatch }) => {

  const handleChange = (e) =>
    dispatch({
      type: SET_SELLER_PRICE,
      value: {
        id: id,
        field: e.target.name,
        value: e.target.value,
      },
    });

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <TextField
          type="number"
          inputProps={{
            step: "0.01"
          }}
          name="primary"
          label="New price"
          variant="standard"
          value={data.primary}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          required
        /> 

        <TextField
          type="number"
          inputProps={{
            step: "0.01"
          }}
          name="original"
          label="Old price"
          variant="standard"
          value={data.original}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          required
        /> 
      </Stack>

      <Stack direction="row" spacing={2}>
        <TextField
          name="amount"
          label="Saving amount"
          variant="standard"
          value={data.savings.amount}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          disabled
        /> 

        <TextField
          name="Saving percentage"
          label="Saving percentage"
          variant="standard"
          value={data.savings.percentage}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          fullWidth
          disabled
        /> 
      </Stack>
    </Stack>
  );
};

export default Price;
