const Fields = (category) => {
  switch (category) {
    case 'products-articles':
      return [
        'Published',
        'PublishedOn',
        'Slug',
        'Title',
        'Description',
        'Keywords',
        'Note',
        'Rating',
        'Links',
        'Taxonomies',
        'ImageStatic',
        'ImageDynamic',
        'Gallery',
      ]

    case 'deals-articles':
      return [
        'Published',
        'PublishedOn',
        'Slug',
        'Title',
        'Description',
        'Keywords',
        'ImageStatic',
      ]

    case 'deals-products':
      return [
        'Published',
        'Title',
        'Description',
        'Sellers',
        'ImageStatic',
        'ImageDynamic',
        'Taxonomies',
      ]

    case 'setups-articles':
      return [
        'Published',
        'PublishedOn',
        'Slug',
        'Title',
        'Description',
        'Keywords',
        'Taxonomies',
        'ImageStatic',
        'Gallery',
        'Products',
      ]

    case 'blog-articles':
      return [
        'Published',
        'PublishedOn',
        'Slug',
        'Title',
        'Description',
        'Keywords',
        'ImageStatic',
        'ImageDynamic',
        'Taxonomies',
        'TOC',
      ]

    case 'news-articles':
      return [
        'Published',
        'Slug',
        'Title',
        'Description',
        'Author',
        'Link',
        'Links',
        'ImageStatic',
        'Taxonomies',
      ]

    case 'news-sources':
      return [
        'Slug',
        'Title',
        'Description',
        'Feed',
        'ImageStatic',
        'Cover',
        'Links',
      ]

    case 'affiliates-articles':
      return [
        'Published',
        'PublishedOn',
        'Title',
        'Description',
        'Link',
        'Weight',
        'ImageStatic',
        'ImageDynamic',
        'Taxonomies',
      ]

    case 'ads-articles':
      return [
        'Published',
        'PublishedOn',
        'Title',
        'Description',
        'ActivePeriod',
        'Placement',
        'Weight',
        'Link',
        'ImageStatic',
        'ImageDynamic',
        'Taxonomies',
      ]

    case 'section-index':
      return [
        'Slug',
        'Title',
        'Description',
        'ImageStatic',
        'ImageDynamic',
        'Cover',
        'Links',
      ]

    default:
      return [
        'Slug',
        'Title',
        'Description',
        'ImageStatic',
        'ImageDynamic',
        'Cover',
        'Links',
      ]
  }
};

export default Fields;
