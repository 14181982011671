import React, { useState, useRef, useEffect } from 'react';
import IKMediaLibraryWidgetCore from 'imagekit-media-library-widget';

const config = {
  className: 'media-library-widget',
  dimensions: {
    height: '100%',
    width: '100%',
  },
  view: 'modal',
  renderOpenButton: true,
};

const ImageKitLibrary = ({ onInsert }) => {
  const mounted = useRef(false);
  const elementRef = useRef();
  const clientRef = useRef();

  const callback = (payload) => {
    if (mounted.current) {

      if (payload.eventType === 'CLOSE_MEDIA_LIBRARY_WIDGET') {
        mounted.current = false;
      };

      if (payload.eventType === 'INSERT') {
        const images = payload.data.reduce((acc, image) => {
          acc.push({
            url: image.filePath,
            width: image.width,
            height: image.height,
          });

          return acc;
        }, []);

        onInsert(images);
        mounted.current = false;
      };
    };
  };

  const openWindow = () => mounted.current = true;

  useEffect(() => {
    // BUG: This will load an iframe everytime gets called
    if (!clientRef.current) {
      clientRef.current = new IKMediaLibraryWidgetCore({
        container: elementRef.current,
        ...config,
      }, callback);
    };
  }, []);

  return (
    <div ref={elementRef} onClick={openWindow}>
    </div>
  );
};

export default ImageKitLibrary;
