import React, { useState } from 'react';
import Modal from 'components/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { auth, firestore } from 'utilities/firebase';
import { collection, addDoc } from 'firebase/firestore';

const sections = [
  { value: 'news', label: 'News' },
  { value: 'deals', label: 'Deals' },
  { value: 'products', label: 'Products' },
  { value: 'setups', label: 'Setups' },
  { value: 'blog', label: 'Blog' },
]

const ToDo = ({ data, open, close }) => {
  const [ section, setSection ] = useState('products');
  const [ content, setContent ] = useState(data?.data()?.content || '');

  const handleCreate = async () => {
    const { title, description, link, image } = data.data();

    const toDoData = {
      createdOn: new Date(),
      publishedOn: new Date(),
      title,
      description,
      link,
      image,
      content,
      partOf: {
        users: {
          authors: [auth.currentUser.uid],
        }
      }
    };

    const colRef = collection(firestore, section, 'todos', 'content');

    await addDoc(colRef, toDoData);

    close();
  };

  return (
    <Modal
      isOpen={open}
      handleClose={close}
      title="Create To Do"
    >
      <Stack direction="column" spacing={2}>
        <Typography>Add: {data?.data()?.title || ''}</Typography>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography>To:</Typography>

          <Select
            labelId="section"
            id="section"
            name="section"
            label="Section"
            value={section}
            onChange={(e) => setSection(e.target.value)}
            style={{ width: '100%' }}
          >
            {sections.map((section) => 
              <MenuItem
                key={section.value}
                value={section.value}
              >
                {section.label}
              </MenuItem>
            )}
          </Select>
        </Stack>

        <TextField
          id="content"
          label="Content"
          placeholder="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)} 
          multiline
        />

        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={close}
          >
            Cancel
          </Button>  

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreate}
            disabled={!data}
          >
            Create
          </Button>  
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ToDo;
