import React, { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, connectAutoComplete } from 'react-instantsearch-dom';

import Modal from 'components/Modal';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY,
);

const SearchBox = ({
  hits,
  currentRefinement,
  refine,
  onSelect
}) =>
  <Autocomplete
    options={hits}
    onChange={(e,val) => onSelect(val)}
    input={currentRefinement}
    onInputChange={(e) => refine(e.target.value)}
    getOptionLabel={(option) => option.title}
    renderInput={(params) => 
      <TextField
        {...params}
        label="Search article"
        placeholder="Search article.."
        InputProps={{
          ...params.InputProps,
          type: 'search',
        }}
        variant="standard"
        fullWidth
        required
      />
    }
    autoHighlight
    fullWidth
  />

const AddAltLink = ({ data, add, open, close }) => {
  const [target, setTarget] = useState({});

  useEffect(() => {
    if (open) setTarget({});
  }, [open]);

  const CustomAutocomplete = connectAutoComplete(SearchBox);

  const handleSelect = (hit) => setTarget(hit);

  const handleAdd = () => {
    add(target.objectID);
    close();
  }

  return (
    <Modal
      isOpen={open}
      handleClose={close}
      title="Add alternative link"
    >
      <Stack direction="column" spacing={2}>
        <InstantSearch
          indexName="news_articles"
          searchClient={searchClient}
        >
          <Configure
            hitsPerPage={10}
          />

          <CustomAutocomplete onSelect={handleSelect} />
        </InstantSearch>

        <Typography variant="body1">
          Add
        </Typography>

        <Box component={Paper} elevation={2} sx={{ padding: 2 }}>
          <Typography variant="body1">
            {data.title}
          </Typography>
        </Box>

        <Typography variant="body1">
          To
        </Typography>

        <Box component={Paper} elevation={2} sx={{ padding: 2 }}>
          <Typography variant="body1">
            {target.title ? target.title : 'Select article'}
          </Typography>
        </Box>

        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={close}
          >
            Cancel
          </Button>  

          <Button
            variant="contained"
            color="primary"
            onClick={handleAdd}
            disabled={!target}
          >
            Add
          </Button>  
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AddAltLink;
