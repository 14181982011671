import React, { useContext } from 'react';
import Context from '../Context';

import Fields from './Fields';

import Published from '../Fields/Published';
import PublishedOn from '../Fields/Dates/PublishedOn';
import Content from '../Fields/Content';
import Slug from '../Fields/Slug';
import Title from '../Fields/Title';
import Description from '../Fields/Description';
import Note from '../Fields/Note';
import Keywords from '../Fields/Keywords';
import Rating from '../Fields/Rating';
import Taxonomies from '../Fields/Taxonomies';
import ImageStatic from '../Fields/ImageStatic';
import ImageDynamic from '../Fields/ImageDynamic';
import Cover from '../Fields/Cover';
import Gallery from '../Fields/Gallery';
import TOC from '../Fields/TOC';
import Feed from '../Fields/Feed';
import Products from '../Fields/Products';
import Link from '../Fields/Link';
import Links from '../Fields/Links';
import Author from '../Fields/Author';
import Sellers from '../Fields/Sellers';
import Weight from '../Fields/Weight';
import Placement from '../Fields/Placement';
import ActivePeriod from '../Fields/ActivePeriod';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const componentMapping = {
  Slug,
  Title,
  Description,
  Note,
  Keywords,
  Rating,
  Taxonomies,
  ImageStatic,
  ImageDynamic,
  Cover,
  Gallery,
  Links,
  TOC,
  Feed,
  Products,
  Published,
  PublishedOn,
  Link,
  Author,
  Sellers,
  Weight,
  Placement,
  ActivePeriod,
};

const Form = ({ edit, type, save, close }) => {
  const { state, dispatch } = useContext(Context);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    save();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
        >
          <Stack
            spacing={2}
            sx={{ flex: 1 }}
          >
            {Fields(type).map((componentName) => {
              const Component = componentMapping[componentName];

              return (
                <Component
                  key={componentName}
                  type={type}
                  data={state}
                  dispatch={dispatch}
                />
              )
            })}
          </Stack>

          <Box sx={{ flex: 1, height: '100%' }}>
            <Content data={state} dispatch={dispatch} />
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            onClick={close}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {edit ? 'Save' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
};

export default Form;
