import React, { useState } from 'react';

import {
  DndContext,
  closestCenter,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import Item from './Item';
import Search from 'components/Search';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import {
  ADD_TAXONOMY,
  DELETE_TAXONOMY,
  ADD_TAXONOMY_ITEM,
  MOVE_TAXONOMY_ITEM,
  DELETE_TAXONOMY_ITEM,
} from '../../Constants';

const Products = ({ data, dispatch }) => {
  const [ editing, setEditing ] = useState(false);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const handleAdd = () => 
    dispatch({
      type: ADD_TAXONOMY,
      value: {
        section: 'products',
        subsection: 'articles',
      }
    });

  const handleRemove = () =>
    dispatch({
      type: DELETE_TAXONOMY,
      value: {
        section: 'products',
        subsection: 'articles',
      }
    });

  const handleAddItem = (obj) => 
    dispatch({
      type: ADD_TAXONOMY_ITEM,
      value: {
        section: 'products',
        subsection: 'articles',
        value: obj.objectID,
      }
    });

  const handleDragEnd = (e) =>
    dispatch({
      type: MOVE_TAXONOMY_ITEM,
      value: {
        section: 'products',
        subsection: 'articles',
        value: e,
      }
    });

  const handleRemoveItem = (index) => 
    dispatch({
      type: DELETE_TAXONOMY_ITEM,
      value: {
        section: 'products',
        subsection: 'articles',
        value: index,
      }
    });

  return (
    <Stack component={Paper} spacing={2} sx={{ padding: (t) => t.spacing(2) }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>
          Linked Products
        </Typography>

        <IconButton
          onClick={data.partOf.products && data.partOf.products.articles ? handleRemove : handleAdd}
          size="small"
        >
          {data.partOf.products && data.partOf.products.articles ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Stack>

      {data.partOf.products && data.partOf.products.articles &&
        <React.Fragment>
          <Divider />

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={data.partOf.products.articles}
              strategy={verticalListSortingStrategy}
            >
              {data.partOf.products.articles.map((product, index) =>
                <Item
                  key={product}
                  index={index}
                  id={product}
                  remove={handleRemoveItem}
                  editing={editing} 
                />
              )}
            </SortableContext>
          </DndContext>

          <Divider />

          <Stack direction="row" alignItems="center" spacing={2}>
            <Search
              section="products"
              subsection="articles"
              select={handleAddItem}
            />

            <IconButton
              onClick={() => setEditing(!editing)}
              size="small"
            >
              {editing ? <DoneIcon fontSize="small" /> : <EditIcon fontSize="small" />}
            </IconButton>
          </Stack>
        </React.Fragment>
      }
    </Stack>
  );
};

export default Products;
