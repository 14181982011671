import React, { useState, useEffect } from 'react';
import merge from "lodash/merge";

import Taxonomy from './Taxonomy';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { SET_DATA } from '../../Constants';

const model = () => ({
  products: {
    brands: [],
    categories: [],
    collections: [],
  },
  setups: {
    collections: [],
  },
  news: {
    sources: [],
    collections: [],
    topics: [],
    categories: [],
  },
  deals: {
    collections: [],
  },
});

const Taxonomies = ({ data, dispatch }) => {
  const [state, setState] = useState(model());

  useEffect(() => {
    const updated = merge(model(), data.partOf);

    setState(updated);
  }, [data.partOf]);

  const handleChange = (section, subsection, arr) =>
    dispatch({
      type: SET_DATA,
      value: {
        field: 'partOf',
        value: {
          ...data.partOf,
          [section]: {
            ...data.partOf[section],
            [subsection]: [
              ...arr
            ]
          }
        },
      },
    });

  return (
    <Stack
      component={Paper}
      spacing={1}
      sx={{ padding: (theme) => theme.spacing(2) }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle">
          Part of
        </Typography>
      </Stack>

      <Stack spacing={2}>
        {Object.keys(state).map((section, sectionIndex) => {
          if (Object.keys(state)[sectionIndex] !== 'users')
            return (
              <Accordion key={`section-${section}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {Object.keys(state)[sectionIndex]}
                  </Typography> 
                </AccordionSummary>

                <AccordionDetails>
                  <Stack
                    key={`section-${sectionIndex}`}
                    component={Paper}
                    spacing={1}
                    sx={{ flex: 1, padding: (theme) => theme.spacing(2) }}
                  >
                    {Object.keys(state[section]).map((subsection, subsectionIndex) => {
                      if (section === 'products' && subsection === 'articles') return;

                      return (
                        <Taxonomy
                          key={`subsection-${section}-${subsectionIndex}`}
                          section={Object.keys(state)[sectionIndex]}
                          subsection={Object.keys(state[section])[subsectionIndex]}
                          ids={state[section][subsection]}
                          onChange={handleChange}
                        />
                      );
                    })}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )
        })}
      </Stack>
    </Stack>
  );
};

export default Taxonomies;
